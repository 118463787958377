<template>
  <div class="index">
    <div style="position: fixed;background:rgba(249, 249, 249, 1);width:100%;left:0;top:0;padding: 27px 16px 12px;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">佣金提现</span>
    </div>
    <div style="height: 278px;background:#fff;padding:15px 16px;border-radius: 12px;margin:15px 16px 0">
      <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align: center;">{{date}}  -  至今</div>
      <div style="color:rgba(44, 54, 74, 1);font-size:32px;font-weight: bold;text-align: center;">￥{{amount}}</div>
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;text-align: center;">累计消耗</div>
      <div style="height: 1px;background:rgba(201, 204, 211, 0.5);margin:16px 0 21px;"></div>
      <div style="display: flex;line-height: 32px;margin-bottom:16px;">
        <div style="display: inline-block;width:115px;">
          <span style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">兼职推广</span>
          <span style="color:rgba(120, 86, 255, 1);font-size:15px;font-weight: bold;">x5%</span>
        </div>
        <span style="flex:1;color:rgba(44, 54, 74, 1);font-weight: bold;text-align: center;">￥{{((amount*0.05).toFixed(2)==0?0:(amount*0.05).toFixed(2))}}</span>
        <span @click="getMoney" style="display: inline-block;width:64px;height:32px;text-align: center;line-height: 32px;color:#fff;font-size:13px;background:rgba(120, 86, 255, 1);border-radius: 6px;">提现</span>
      </div>
      <div style="display: flex;line-height: 32px;margin-bottom:16px;">
        <div style="display: inline-block;width:115px;">
          <span style="color:rgba(103, 110, 125, 1);font-size:15px;">认证代理</span>
          <span style="color:rgba(246, 76, 79, 1);font-size:15px;">x20%</span>
        </div>
        <span style="flex:1;color:rgba(44, 54, 74, 1);font-weight: bold;text-align: center;">￥0</span>
        <span style="display: inline-block;width:64px;height:32px;text-align: center;line-height: 32px;color:#fff;font-size:13px;background:rgba(120, 86, 255, 0.12);border-radius: 6px;color:rgba(120, 86, 255, 1)">去升级</span>
      </div>
      <div style="display: flex;line-height: 32px;">
        <div style="display: inline-block;width:115px;">
          <span style="color:rgba(103, 110, 125, 1);font-size:15px;">驻点服务商</span>
          <span style="color:rgba(246, 76, 79, 1);font-size:15px;">x20%</span>
        </div>
        <span style="flex:1;color:rgba(44, 54, 74, 1);font-weight: bold;text-align: center;">￥0</span>
        <span style="display: inline-block;width:64px;height:32px;text-align: center;line-height: 32px;color:#fff;font-size:13px;background:rgba(120, 86, 255, 0.12);border-radius: 6px;color:rgba(120, 86, 255, 1)">去升级</span>
      </div>
    </div>
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:15px;padding-left:16px;">提现记录</div>
    <div style="padding:12px 16px 24px;background:rgba(249, 249, 249, 1);overflow-y: auto;flex: 1" @scroll="handleScroll">
      <div style="background:#fff;border-radius: 12px;padding: 16px;">
        <div v-if="smsList.length!=0">
          <div style="height: 46px;display: flex;margin-bottom:16px;" v-for="(item,index) in smsList" :key="index">
            <div style="display: inline-block;position: relative">
              <img src="../assets/image/money.png" style="width: 24px;height: 24px;border-radius: 12px;margin-top:8px;" alt="">
            </div>
            <div style="flex: 1;margin:0 8px;">
              <div style="color:rgba(44, 54, 74, 1);font-size: 14px;font-weight: bold;">{{item.no}}</div>
              <div style="color:rgba(179, 184, 193, 1);font-size:12px;margin-top:4px;">{{item.withdrawalTime}}</div>
            </div>
            <div>
              <div style="color:rgba(44, 54, 74, 1);font-size:14px;font-weight: bold;"><span style="font-weight: normal">+</span>￥{{item.amount}}</div>
              <div v-if="item.status==1" style="color:rgba(255, 141, 26, 1);font-size:12px;">提现中</div>
              <div v-if="item.status==2" style="color:rgba(74, 179, 100, 1);font-size:12px;">提现成功</div>
              <div v-if="item.status==3" style="color:rgba(246, 76, 79, 1);font-size:12px;">提现失败</div>
            </div>
          </div>
        </div>
        <div v-if="smsList.length==0" style="text-align: center;">
          <img style="width:40%;margin-top:50px;" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
    return {
      locale,
      zhCN,
      year: '',
      month: '',
      day: '',
      hangUpSms: '',
      smsList: [
        // {
        //   no: 'NO.1280905293877739520',
        //   amount: 0.16,
        //   withdrawalTime: '2024-09-04 15:00:20',
        //   status: 2
        // },
        // {
        //   no: 'NO.1280905293877739520',
        //   amount: 0.16,
        //   withdrawalTime: '2024-09-04 15:00:20',
        //   status: 2
        // },
        // {
        //   no: 'NO.1280905293877739520',
        //   amount: 0.16,
        //   withdrawalTime: '2024-09-04 15:00:20',
        //   status: 2
        // }
      ],
      current: 1,
      totalNum: 0,
      showPopover: false,
      starText: '全部状态',
      starId: null,
      amount: '',
      date: ''
    }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    querySmsList() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/withdrawalList', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.smsList = response.data.data
          console.log(response.data.data)
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    handleScroll() {
      // const { scrollTop, scrollHeight, clientHeight } = event.target;
      // if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
      //     if(this.totalNum != this.smsList.length){
      //         this.current ++
      //         this.querySmsList()
      //     } 
      // }
    },
    viewDel(id) {
      this.$router.push({
          path: '/callDel',
          query:{
              id: id,
          }
      });
    },
    onSelect(action) {
      this.current = 1
      this.starText = action.text
      this.starId = action.id
      this.querySmsList()
    },
    getMoney() {
      this.axios( 
        {
          method:'post',
          url:'/api/user/withdrawal', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.amount = 0
          this.querySmsList()
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    }
  },
  mounted() {
    // const today = new Date(); // 创建一个新的 Date 对象
    // this.year = today.getFullYear(); // 获取年份
    // this.month = (today.getMonth() + 1)<10?'0'+(today.getMonth() + 1):(today.getMonth() + 1); // 获取月份（0-11），所以要加1
    // this.day = today.getDate()<10?'0'+today.getDate():today.getDate(); // 获取日期（1-31）
    // this.hangUpSms = this.$route.query.hangUpSms==1?false:true
    // this.querySmsList()
    this.amount = this.$route.query.amount
    this.date = this.$route.query.date
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 0 0;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
</style>
