import Vue from 'vue';
import VueRouter from 'vue-router';
// import Login from '../tpl/login.vue';
import index from '../tpl/index.vue';
import contacts from '../tpl/contacts.vue';
import sharePage from '../tpl/sharePage.vue';
import sortList from '../tpl/sortList.vue';
import detail from '../tpl/detail.vue';
import callDel from '../tpl/callDel.vue';
import callShare from '../tpl/callShare.vue';
import robotTask from '../tpl/robotTask.vue';
import mine from '../tpl/mine.vue';
import endSet from '../tpl/endSet.vue';
import contactDel from '../tpl/contactDel.vue';
import editContact from '../tpl/editContact.vue';
import endMsg from '../tpl/endMsg.vue';
import recharge from '../tpl/recharge.vue';
import chargeRecord from '../tpl/chargeRecord.vue';
import accountReport from '../tpl/accountReport.vue';
import reportDel from '../tpl/reportDel.vue';
import login from '../tpl/login.vue';
import proxyShare from '../tpl/proxyShare.vue';
import withdrawal from '../tpl/withdrawal.vue';
import { sessionData } from "../storage/storage";

Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/index' },
    { 
        path: '/index', component: index,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/contacts', component: contacts,
        meta: {
            requireAuth: false,  
        }    
    },
    { 
        path: '/sharePage', component: sharePage,
        meta: {
            requireAuth: false,  
        }    
    },
    { 
        path: '/sortList', component: sortList,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/callDel', component: callDel,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/detail', component: detail,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/callShare', component: callShare,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/robotTask', component: robotTask,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/mine', component: mine,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/endSet', component: endSet,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/contactDel', component: contactDel,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/editContact', component: editContact,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/endMsg', component: endMsg,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/recharge', component: recharge,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/chargeRecord', component: chargeRecord,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/accountReport', component: accountReport,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/reportDel', component: reportDel,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/login', component: login,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/proxyShare', component: proxyShare,
        meta: {
            requireAuth: false,  
        }   
    },
    { 
        path: '/withdrawal', component: withdrawal,
        meta: {
            requireAuth: false,  
        }   
    },
]
const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    console.log(sessionData('get','token'))
    if (to.meta.requireAuth) {  // 判断该路由是否需要登录权限
        if (sessionData('get','token')) {  // 获取当前的token是否存在
            next();
        } else {
            next('/login')
        }
    }else { // 如果不需要权限校验，直接进入路由界面
        next();
    }
});
export default router;