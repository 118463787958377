<template>
  <div class="index" @click="hideMember">
    <div style="background:rgba(249, 249, 249, 1);padding: 27px 16px 0 0;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">{{projectName}}</span>
    </div>
    <div style="padding:12px 0 0px;border-radius:12px;margin-top:17px;flex:1;overflow-y:auto;">
      <div style="border-radius: 12px;height:100%;display:flex;flex-direction:column;">
        <div style="width:100%;height: 100%;position: relative;height: 60px;">
          <a-date-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:16px;" />
          <div v-if="month!=''" style="position: absolute;top:0px;left:0px;font-size:20px;z-index: 1;">
            <span style="color:rgba(44, 54, 74, 1);font-size:40px;font-weight: bold;">{{month}}</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 14px;margin:0 8px">/</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 16px;font-weight: bold;margin-right:6px;">{{ day }}</span>
            <a-icon type="caret-down"/>
            <span style="color:rgba(179, 184, 193, 1);font-size:12px;margin-left:16px;">{{year}}</span>
          </div>
          <img :src="avatar" style="width:60px;height:60px;border-radius:30px;position: absolute;right:0;top:0px;" alt="">
          <div v-if="month==''" style="position: absolute;top:0px;left:0px;font-size:16px;z-index: 1;color:#ccc">请选择日期</div>
        </div>
        <div v-if="robotDel.isOwner" style="margin-top: 15px;position: relative;">
          <div @click.stop="chooseMember" style="display: inline-block;color:rgba(103, 110, 125, 1);font-size:13px;">
            <span>成员筛选</span>
            <a-icon style="margin-left: 6px;" type="caret-down" />
          </div>
          <span style="color:rgba(179, 184, 193, 1);font-size:13px;margin-left:24px;">已选{{selectedMember.length}}人</span>
          <div @click.stop="chooseMember" v-if="ifShowMember" style="width: 128px;max-height:200px;min-height:60px;box-shadow: 0px 0px 56px 0px rgba(44, 54, 74, 0.24);background:#fff;position: absolute;top:28px;left:0px;z-index:1000;border-radius: 12px;overflow-y: auto;padding:5px 0px 5px 40px;font-size:13px;font-weight:bold;">
            <div @click="selectMember(index)" style="position: relative;height:40px;padding:10px 0;" :class="item.selected?'selected-member':''" v-for="(item,index) in memberList" :key="index">
              <img v-if="item.selected" style="width:19px;position: absolute;left:-20px;top:10px;" src="../assets/image/member-check.svg" alt="">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
        <div style="overflow-y: auto;flex: 1;margin-top:15px;">
          <div style="background:#fff;margin-bottom:16px;padding:12px 16px 0;border-radius:12px;">
            <div style="color:rgba(44, 54, 74, 1);font-size:13px;font-weight:bold;">成本分析</div>
            <div style="height:1px;background:rgba(201, 204, 211, 0.5);margin:10px 0 15px;"></div>
            <div>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;">{{ accountDel.price }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">消耗金额(￥)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;">{{ accountDel.clueCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">线索数</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;">{{ accountDel.avgCost }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">平均线索成本(￥)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;">{{ accountDel.connectCluePercent }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">通话线索率</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>

          <div style="background:#fff;margin-bottom:16px;padding:12px 16px 0;border-radius:12px;">
            <div style="color:rgba(44, 54, 74, 1);font-size:13px;font-weight:bold;">营销分析</div>
            <div style="height:1px;background:rgba(201, 204, 211, 0.5);margin:10px 0 15px;"></div>
            <div>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.recordCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">拨号量</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.connectCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">接听量</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.connectRecordPercent }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">接听率</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.avgDuration }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">平均通话时长(s)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.smsCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">数字名片短信(条)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.visitorCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">数字名片访客(人)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>

          <div style="background:#fff;margin-bottom:16px;padding:12px 16px 0;border-radius:12px;">
            <div style="color:rgba(44, 54, 74, 1);font-size:13px;font-weight:bold;">操作行为</div>
            <div style="height:1px;background:rgba(201, 204, 211, 0.5);margin:10px 0 15px;"></div>
            <div>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.clueCheckCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">线索复核数</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.clueCheckPercent }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">线索复核率</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.avgCheckTime }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">复核及时性(min)</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.collectCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">线索收藏数</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.clueCollectPercent }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">线索收藏率</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>

          <div style="background:#fff;margin-bottom:16px;padding:12px 16px 0;border-radius:12px;">
            <div style="color:rgba(44, 54, 74, 1);font-size:13px;font-weight:bold;">AI纠错学习</div>
            <div style="height:1px;background:rgba(201, 204, 211, 0.5);margin:10px 0 15px;"></div>
            <div>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.errorCorrectionCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">纠错提交数</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.waitingCorrectionCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">待纠错</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.successCorrectionCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">纠错成功</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="even-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.failCorrectionCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">纠错失败</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12" style="margin-bottom:24px;">
                  <div style="display:flex;" class="base-num">
                    <div style="flex:1;">
                      <div style="color:rgba(44, 54, 74, 1);font-size:15px; font-weight:bold;">{{ accountDel.noCorrectionCount }}</div>
                      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">无需纠错</div>
                    </div>
                    <img src="../assets/image/card.png" style="width:28px;height:28px;margin-top:4px;margin-right:12px;" alt="">
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        locale,
        zhCN,
        year: '',
        month: '',
        day: '',
        smsList: [],
        current: 1,
        totalNum: 0,
        ifShowMember: false,
        memberList: [],
        selectedMember: [],
        robotDel: '',
        projectId: '',
        userId: '',
        accountDel: '',
        projectName: '',
        avatar: ''
      }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    onChange(date, dateString) {
      let str = dateString.split("-")
      this.year = str[0]
      this.month = str[1]
      this.day = str[2]
      this.querySmsList()
    },
    querySmsList() {
      let str = ''
      if(this.selectedMember.length > 0){
        for(let i = 0; i < this.selectedMember.length; i++){
          str += this.selectedMember[i]+','
        }
        str = str.slice(0, -1)
      }
      this.axios( 
        {
          method:'get',
          url:'/api/bill/billDetail', 
          params: {
            projectId: this.projectId,
            userIds: str,
            date: this.year+'-'+this.month+'-'+this.day
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.accountDel = response.data.data
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    hideMember() {
      this.ifShowMember = false
    },
    showMember() {
      this.ifShowMember = true
    },
    chooseMember() {
      this.ifShowMember = true
    },
    selectMember(index) {
      if(this.memberList[index]['selected']){
        this.memberList[index]['selected'] = false
      }else{
        this.memberList[index]['selected'] = true
      }
      this.selectedMember = []
      for(let i = 0; i < this.memberList.length; i++){
        if(this.memberList[i]['selected']==true){
          this.selectedMember.push(this.memberList[i]['id'])
        }
      }
      this.querySmsList()
    },
    queryRobotDetail() {
      this.axios( 
        {
          method:'get',
          url:'/api/project/detail', 
          params: {
            id: this.projectId
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.robotDel = response.data.data
          this.memberList = response.data.data.memberList
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
  },
  mounted() {
    this.year = this.$route.query.year
    this.month = this.$route.query.month
    this.day = this.$route.query.day
    this.projectId = this.$route.query.project_id
    this.userId = this.$route.query.user_id
    this.projectName = this.$route.query.project_name
    this.avatar = this.$route.query.avatar
    this.selectedMember.push(this.$route.query.user_id)
    for(let i = 0; i < this.memberList.length; i++){
      if(this.memberList[i]['id'] == this.$route.query.user_id){
        this.memberList[i]['selected'] = true
      }
    }
    this.querySmsList()
    this.queryRobotDetail()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px 16px 24px;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
  .base-num{
    padding-right: 16px;
  }
  .even-num{
    padding-left: 16px;
  }
  .selected-member{
    color: rgba(120, 86, 255, 1);
  }
</style>
