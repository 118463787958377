<template>
  <div class="index">
    <div class="top-head">
      <div class="top-title" style="padding: 30px 16px 8px;position: fixed;width:100%;top:0;left:0;z-index: 1000">
        <a-icon @click="goback" style="float: left;color:#fff;font-size:20px;" type="left" />
        <a-icon @click="showConfirm" style="float: right;color:#fff;font-size:20px;" type="delete" />
      </div>
      <div style="clear: both;"></div>
      <div style="text-align: center;margin-top: 30px;">
        <img style="width: 108px;opacity: 0.7;" src="../assets/image/user.png" alt="">
      </div>
      <div style="color:#fff;font-size:28px;font-weight: bold;text-align: center;margin-top: 16px;">{{contactDel.name}}</div>
    </div>
    <div style="padding: 17px 16px 68px;background: rgba(249, 249, 249, 1)">
      <a-row>
        <a-col :span="12" style="padding-right: 7px;">
          <div @click="aiSearch" style="text-align: center;height: 74px;background:#fff;padding-top: 16px;border-radius: 8px;">
            <a-icon style="color:rgba(120, 86, 255, 1);font-size:18px;font-weight: bold;" type="search" />
            <div style="color:rgba(120, 86, 255, 1);font-size:13px;font-weight: bold;margin-top:3px;">AI搜索</div>
          </div>
        </a-col>
        <a-col :span="12" style="padding-left: 7px;">
          <div @click="aiCall" style="text-align: center;text-align: center;height: 74px;background:#fff;padding-top: 16px;border-radius: 8px;">
            <img style="width:20px;" src="../assets/image/phone-calling.svg" alt="">
            <div style="color:rgba(120, 86, 255, 1);font-size:13px;font-weight: bold;margin-top:3px;">AI通话</div>
          </div>
        </a-col>
      </a-row>
      <div style="padding: 12px 16px 0;background: #fff;height: 74px;margin-top: 12px;border-radius: 8px;display: flex;">
        <div style="flex: 1;">
          <div style="color:rgba(44, 54, 74, 1);font-size:18px;font-weight: bold;">{{contactDel.phone}}</div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top: 4px;">{{contactDel.belonging}}  |  {{contactDel.isp}}</div>
        </div>
        <div @click="callNumber(contactDel.phone)" style="display: inline-block;width:44px;height:44px;border-radius: 22px;background:rgba(243, 245, 248, 1);text-align: center;line-height: 50px;margin-top: 5px;">
          <a-icon type="phone" style="color:rgba(201, 204, 211, 1);font-size:20px;transform: rotate(90deg);" />
        </div>
      </div>
      <div style="padding: 24px 16px;background:#fff;border-radius: 8px;margin-top:12px;">
        <a-rate @change="changeLevel" v-model="contactDel.level" />
        <span style="color:rgba(255, 153, 0, 1);font-size:16px;margin-left:16px;">{{ contactDel.level }}</span>
        <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top: 24px;">来源</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top: 3px;">{{contactDel.source}}</div>
        <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top: 24px;">备注</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:3px;white-space: pre-wrap;">{{contactDel.remark}}</div>
      </div>
      <div style="padding: 16px 16px 1px;background:#fff;border-radius: 8px;margin-top:16px;">
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-bottom:20px">AI通话记录</div>
        <div style="margin-bottom:16px;display: flex;" v-for="(item,index) in contactDel.recordList" :key="index" @click="viewDel(item.id)">
          <div style="flex: 1;">
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">{{item.projectName}}</div>
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.callTime}}</div>
          </div>
          <div style="display: inline-block;margin-top: 12px;">
            <span style="color:rgba(103, 110, 125, 1);font-size:13px;">{{item.duration}}</span>
            <img style="margin-left:8px;width:10px;opacity: 0.5;" src="../assets/image/sanjiao.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px;width: 100%;position: fixed;bottom:24px;padding:0 16px;display: flex;">
      <span @click="changeCollectStatus(1)" v-if="contactDel.collectStatus==2" style="display: inline-block;width:60px;height:60px;border-radius: 30px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);text-align: center;line-height: 60px;background:#fff;;">
        <img style="width:24px;" src="../assets/image/black-star.svg" alt="">
      </span>
      <span @click="changeCollectStatus(2)" v-if="contactDel.collectStatus==1" style="display: inline-block;width:60px;height:60px;border-radius: 30px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);text-align: center;line-height: 60px;background:#fff;;">
        <img style="width:24px;" src="../assets/image/star-kong.png" alt="">
      </span>
      <div style="flex:1;margin-left: 12px;background:#fff;height: 60px;border-radius: 30px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);padding: 11px 12px 0 14px;">
        <div @click="editContact" style="height: 38px;background:rgba(120, 86, 255, 1);border-radius: 19px;text-align: center;color:#fff;line-height: 38px;font-weight: bold;font-size: 13px;">
          <a-icon type="edit" />
          <span style="margin-left: 8px;">编辑联系人</span>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="ifShowSence" title="选择AI场景">
      <van-picker
          title=""
          :columns="areaNameList"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="changeCity"
          confirm-button-text=""
          cancel-button-text=""
          :show-toolbar="false"
      />
      <div style="padding:0 20px 24px">
        <div @click="onConfirm" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;">确认呼叫</div>
      </div>
    </van-action-sheet>
    <div v-if="ifShowTip1" style="width: 100%;height:40px;position: fixed;left:0;bottom: 24px;padding: 0 16px;">
      <div style="height: 40px;background:rgba(246, 76, 79, 1);border-radius: 8px;color:#fff;font-size:13px;text-align: center;line-height: 40px;">
        <a-icon type="close" style="font-size:14px;margin-right:4px;font-weight: bold;" />
        <span>余额不足，请充值</span>
      </div>
    </div>
    <div v-if="ifShowTip2" style="width: 100%;height:40px;position: fixed;left:0;bottom: 24px;padding: 0 16px;">
      <div style="height: 40px;background:rgba(44, 54, 74, 1);border-radius: 8px;color:#fff;font-size:13px;text-align: center;line-height: 40px;">
        <a-icon type="check" style="font-size:14px;margin-right:4px;font-weight: bold;" />
        <span>拨号中，请关注最新话单</span>
      </div>
    </div>
    <div v-if="ifShowTip3" style="width: 100%;height:40px;position: fixed;left:0;bottom: 24px;padding: 0 16px;">
      <div style="height: 40px;background:rgba(255, 195, 0, 1);border-radius: 8px;color:#fff;font-size:13px;text-align: center;line-height: 40px;">
        <img style="width:20px;margin-right:2px;" src="../assets/image/ku.svg" alt="">
        <span>对不起，未搜索到结果</span>
      </div>
    </div>
    <van-action-sheet v-model="ifShowDel" :closeable="false" title="">
      <div style="padding: 20px">
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;text-align: center;">你确定要将此联系人从列表中删除吗，删除后数据将无法再次恢复</div>
      </div>
      <div style="padding:0 20px 24px">
        <div @click="deleteContact" style="height: 40px;background:#fff;border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:rgba(246, 76, 79, 1);">删除</div>
        <div @click="offDel" style="height: 40px;background:rgba(179, 184, 193, 0.2);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:rgba(103, 110, 125, 1);margin-top: 12px;">取消</div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="ifShowSearch" title="AI搜索">
      <div style="text-align: center;margin:80px 0 35px">
        <a-icon style="font-size:25px;" type="search" />
      </div>
      <div style="color:rgba(164, 164, 164, 1);font-size:16px;text-align: center;margin-bottom: 82px;">正在搜索，请耐心等待...</div>
    </van-action-sheet>
    <van-action-sheet v-model="ifShowResult" title="搜索结果">
      <div style="max-height:300px;overflow-y: auto;">
        <div v-for="(item,index) in searchResult" :key="index">
          <div style="margin-top:20px;padding:0 20px;">
            <div style="height:36px;line-height:36px;text-align:center;background:rgba(243, 245, 248, 1);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:12px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">企业信息</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">法人代表</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.juridicalPerson}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">注册资本</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.registeredCapital}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">成立日期</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.establishTime}}</div>
          </div>
        </div>
      </div>
      <div style="padding:0 20px 24px;margin-top:24px;">
        <div @click="getResult" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;">知道了</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        stars: 4,
        ifShowSence: false,
        areaNameList: [],
        selectedCityName: '',
        areaIdList: [],
        selectedCityId: '',
        cityName: '',
        ifShowTip1: false,
        ifShowTip2: false,
        ifShowTip3: false,
        ifShowDel: false,
        ifShowSearch: false,
        ifShowResult: false,
        id: '',
        contactDel: '',
        searchResult: [],
        timer: null,
      }
  },
  beforeDestroy() {
    this.stopRefreshing();
  },
  methods: {
    goback() {
      this.$router.push("/contacts")
    },
    showConfirm() {
     this.ifShowDel = true
    },
    editContact() {
      this.$router.push({
        path: '/editContact',
        query:{
          id: this.contactDel.id,
          edit: true,
          name: this.contactDel.name,
          phone: this.contactDel.phone,
          remark: this.contactDel.remark,
          recordId: ''
        }
      });
    },
    onConfirm() {
      this.ifShowSence = false
      if(this.selectedCityId==''&&this.areaIdList.length>0){
        this.selectedCityId = this.areaIdList[0]
      }
      this.axios( 
        {
          method:'get',
          url:'/api/contacts/aiCall', 
          params: {
            phone: this.contactDel.phone,
            projectId: this.selectedCityId
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          if(response.data.data.flag){
            this.$toast('已呼出')
          }else{
            this.ifShowTip1 = true
            setTimeout(()=>{
              this.ifShowTip1 = false
            },1500)
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })

    },
    changeCity(picker, value, index) {
      this.selectedCityName = this.areaNameList[index]
      this.selectedCityId = this.areaIdList[index]
    },
    onCancel() {
      console.log('取消');
      this.ifShowSence = false
    },
    deleteContact() {
      this.ifShowDel = false
      this.axios.post('/api/contacts/delete', {
        id: this.contactDel.id,
      })
      .then(response=> {
        if(response.data.code == 0){
          this.$toast('删除成功')
          this.$router.push({
            path: '/contacts',
          });
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    offDel() {
      this.ifShowDel = false
    },
    getResult() {
      this.ifShowResult = false
    },
    queryChatDel() {
      this.axios( 
        {
          method:'get',
          url:'/api/contacts/detail', 
          params: {
            id: this.id,
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
          this.contactDel = response.data.data
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    changeLevel() {
      this.axios.post('/api/contacts/updateLevel', {
        id: this.contactDel.id,
        level: this.contactDel.level,
      })
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    changeCollectStatus(type) {
      this.axios.post('/api/contacts/updateCollect', {
        id: this.contactDel.id,
        collectStatus: type,
      })
      .then(response=> {
        if(response.data.code == 0){
          this.contactDel.collectStatus = type
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    aiCall() {
      this.ifShowSence = true
    },
    querySence() {
      this.axios( 
        {
          method:'get',
          url:'/api/contacts/projectList', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          let arr = response.data.data
          let idList = [], nameList = []
          for(let i = 0; i < arr.length; i++){
            idList.push(arr[i]['id'])
            nameList.push(arr[i]['name'])
          }
          this.areaIdList = idList
          this.areaNameList = nameList
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    aiSearch() {
      this.ifShowSearch = true
      this.axios( 
        {
          method:'get',
          url:'/api/contacts/aiSearch', 
          params:{
            phone: this.contactDel.phone
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
          this.ifShowSearch = false
          this.searchResult = response.data.data
          if(this.searchResult.length > 0){
            this.ifShowResult = true
          }else{
            this.ifShowTip3 = true
            setTimeout(()=>{
              this.ifShowTip3 = false
            },1500)
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    viewDel(id) {
      this.$router.push({
          path: '/callDel',
          query:{
              id: id,
          }
      });
    },
    startRefreshing() {
      this.timer = setInterval(() => {
        this.queryChatDel()
      },  10000); // 转换为毫秒
    },
    stopRefreshing() {
      if (this.timer) {
        clearInterval(this.timer); // 清除定时器
        this.timer = null; // 重置定时器 ID
      }
    },
    callNumber(phone) {
      window.location.href = `tel:${phone}`;
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.queryChatDel()
    this.querySence()
    this.startRefreshing()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba(249, 249, 249, 1);
  }
  .top-title{
    background: url('../assets/image/top-bg-1.png');
  }
  .top-head{
    background: url('../assets/image/top-bg-1.png');
    height: 287px;
    padding-top: 30px;
  }
  .content {
    padding: 16px 16px 160px;
  }
</style>
