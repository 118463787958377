<template>
  <div class="index">
    <div style="text-align: center">
      <a-icon @click="goback" style="float: left;color:rgba(44, 54, 74, 1);font-size:20px;" type="close" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">编辑客户</span>
      <a-icon @click="saveInfo" style="float: right;color:rgba(120, 86, 255, 1);font-size:22px;" type="check" />
    </div>
    <div style="text-align: center;margin-top:27px;">
      <img style="width: 108px;opacity: 0.5;" src="../assets/image/user.png" alt="">
    </div>
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:27px;">姓名/称呼</div>
    <a-input v-model.trim="nickname" style="border:none;background:#fff;height:52px;margin-top:8px;font-size:17px;font-weight: bold;" placeholder="请输入" />
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:20px;">手机号</div>
    <a-input v-model.trim="phone" type="number" style="border:none;background:#fff;height:52px;margin-top:8px;font-size:17px;" placeholder="请输入" />
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:20px;">备注</div>
    <a-textarea v-model.trim="remark" style="border:none;background:#fff;height:220px;margin-top:8px;font-size:17px;" placeholder="请输入备注信息"  />
    <div v-if="ifShowTip" style="width: 100%;height:40px;position: fixed;left:0;bottom: 24px;padding: 0 16px;">
      <div style="height: 40px;background:rgba(246, 76, 79, 1);border-radius: 8px;color:#fff;font-size:13px;text-align: center;line-height: 40px;">
        <a-icon type="close" style="font-size:14px;margin-right:4px;font-weight: bold;" />
        <span>无法保存，该手机号存在</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        ifShowTip: false,
        nickname: '',
        recordId: '',
        id: '',
        phone: '',
        remark: ''
      }
  },
  methods: {
    goback() {
      this.$router.go(-1); 
    },
    saveInfo() {
      console.log(this.phone)
        this.axios.post('/api/contacts/saveContacts', {
          id: this.id,
          recordId: this.recordId,
          name: this.nickname,
          phone: this.phone.replace(/\s+/g, ''),
          remark: this.remark,
        })
        .then(response=> {
          if(response.data.code == 0){
            if(response.data.data.flag == false){
              this.ifShowTip = true
              setTimeout(()=>{
                this.ifShowTip = false
              },1500)
            }else{
              this.$toast('保存成功')
              this.$router.push({
                path: '/contactDel',
                query:{
                  id: response.data.data.id,
                }
              });
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
  },
  mounted() {
    if(this.$route.query.edit){
      this.id = this.$route.query.id
      this.nickname = this.$route.query.name
      this.phone = this.$route.query.phone
      this.remark = this.$route.query.remark
      this.recordId = this.$route.query.recordId
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 16px 0;
    background: rgba(249, 249, 249, 1);
  }
</style>
