<template>
  <div class="index" @scroll="handleScroll">
    <div class="banner"></div>
    <!-- <div class="head-banner">
    </div> -->
    <div :class="ifShowTitle?'head-banner2':'head-banner1'">
      <div v-if="ifShowTitle" style="color:#fff;font-size:15px;text-align: center;margin-top:20px;">
        <span style="display: inline-block;width:28px;height:28px;border-radius: 14px;background:rgba(8, 7, 7, 1);margin-right:8px;">
          <img style="width:22px;border-radius: 11px;" :src="shareProjectDetail.image" alt="">
        </span>
        <span>{{ shareProjectDetail.name }}</span>
      </div>
    </div>
    <div style="position: absolute;width: 100%;top:80px;">
      <div style="display: flex;z-index: 1;">
        <img style="width: 93px;z-index: 1;" :src="shareProjectDetail.image" alt="">
        <div style="flex:1;">
          <div style="color:rgba(255, 255, 255, 1);font-size:18px;font-weight: bold;padding-top:12px">{{shareProjectDetail.name}}</div>
          <div style="color:rgba(255, 255, 255, 1);font-size:13px;margin-top:10px;">{{shareProjectDetail.description}}</div>
        </div>
      </div>
    </div>
    <div style="background:#fff;position: absolute;top: 184px;width: 100%;border-radius: 16px 16px 0 0;z-index: 1;padding: 0px 16px 12px;">
      <div style="color:rgba(103, 110, 125, 1);font-size:12px;height: 78px;border-radius: 12px;background:rgba(243, 245, 248, 1);margin-top:20px;padding: 12px;display: flex;">
        <img style="width:18px;height:18px;margin-right:9px;opacity: 0.3" src="../assets/image/yinhao.png" alt="">
        <div>{{shareProjectDetail.prologue}}</div>
      </div>
    </div>
    <div style="margin-top: 102px;padding: 0 12px 84px;">
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-bottom: 12px;">精彩对话试听</div>
      <div style="margin-bottom: 20px;" v-for="(item,index) in shareProjectDetail.excitingRecord" :key="index">
        <div style="display: flex;">
          <div style="flex: 1;">
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;">{{item.phone}}</div>
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">呼入{{item.duration}}</div>
            <div style="color:rgba(103, 110, 125, 1);font-size:12px">{{item.callTime}}</div>
          </div>
          <div @click="playVoice(item.voiceUrl)" style="display: inline-block;width: 42px;height:42px;background:rgba(243, 245, 248, 1);border-radius: 21px;margin-left: 12px;margin-top:8px;line-height: 42px;text-align: center;font-size:24px;color:#C9CCD3">
            <a-icon type="caret-right" />
          </div>
        </div>
        <div style="margin-top: 4px;">
          <span v-for="(item1,index1) in item.labelList" :key="index1" style="display: inline-block;height: 24px;border-radius: 4px;line-height: 22px;border: 1px solid rgba(217, 217, 217, 1);background: rgba(243, 245, 248, 1);color:rgba(103, 110, 125, 1);font-size:12px;margin-right: 7px;margin-bottom: 6px;padding: 0 12px;"> 
            {{item1}}
          </span>
        </div>
      </div>
    </div>
    <div style="width: 100%;position: fixed;bottom: 24px;left:0;padding: 0 16px;">
      <div style="height: 60px;width: 100%;display: flex;background: #fff;display:flex;">
        <div @click="goIndex" style="width:60px;height:60px;border-radius:30px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);background:#fff;margin-right:12px;text-align:center;line-height:60px;font-size:20px;">
          <a-icon type="home" />
        </div>
        <div style="flex: 1;margin-left: 12px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);height: 60px;border-radius: 30px;display: flex;padding: 12px 15px;">
          <div style="display: inline-block;width: 86px;color:rgba(44, 54, 74, 1);font-size:13px;font-weight: bold;margin-right: 12px;">
            快来拥有你自己的机器人吧
          </div>
          <div @click="register" style="flex:1;background:rgba(120, 86, 255, 1);border-radius: 19px;height: 38px;padding:9px 21px;text-align: center">
            <span style="color:#fff;font-size:13px;font-weight: bold;margin-left:6px;">注册雇佣</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="screenVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;position: relative;">
            <span>登录注册</span>
            <a-icon @click="closeScreen" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
          </div>
          <div style="text-align: center;margin:24px 0 13px">
            <img style="width: 71px;" src="../assets/image/head1.png" alt="">
          </div>
          <div style="text-align: center">
            <img src="../assets/image/ai.png" style="width: 131px;" alt="">
          </div>
          <div style="height: 88px;background: #fff;border-radius: 8px;margin-top: 28px;position: relative;padding: 22px 20px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);">
              <a-input type="number" style="border: none;background: none;font-size:18px" placeholder="请输入手机号" />
              <span @click="getCode" style="display: inline-block;width: 263px;height: 48px;line-height: 48px;border-radius: 12px;text-align: center;color:#fff;font-size:16px;background: rgba(120, 86, 255, 1);">获取验证码</span>
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size: 12px;margin-top:30px;text-align: center;">若手机号未注册，登录后会自动注册</div>
        </div>
      </div>
    </div>
    <div v-if="pcVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 24px;position: relative;">
            <span>登录注册</span>
            <a-icon @click="closePc" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
          </div>
          <div style="color:rgba(103, 110, 125, 1);font-size:13px;">请输入验证码</div>
          <div style="margin-top: 12px;">
            <a-row style="text-align: center;">
              <a-col :span="6">
                <a-input type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="6">
                <a-input oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="6">
                <a-input oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
              <a-col :span="6">
                <a-input oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
              </a-col>
            </a-row>
          </div>
          <div style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:48px">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
      return {
        locale,
        zhCN,
        dataList: [],
        selectedCdr: 1,
        importVisible: false,
        numberVisible: false,
        numberList: 5,
        pcVisible: false,
        uploadState: 1,
        statusText: '任务已暂停',
        ifshowStatus: false,
        screenVisible: false,
        ifShowCode: false,
        token: '',
        shareProjectDetail: '',
        audio: null,
        ifShowTitle: false
      }
  },
  methods: {
    moment,
    onChange() {

    },
    selectCdr(type) {
      this.selectedCdr = type
      console.log(this.selectedCdr)
    },
    onSearch() {
      console.log("执行搜索功能")
    },
    importData() {
      this.importVisible = true
    },
    closeImport() {
      this.importVisible = false
    },
    manuallyAdd() {
      this.importVisible = false
      this.numberVisible = true
    },
    closeNumber() {
      this.numberVisible = false
    },
    addNumber() {
      this.numberList ++
    },
    sureUpload() {
      this.numberVisible = false
    },
    uploadPc() {
      this.importVisible = false
      this.pcVisible = true
    },
    closePc() {
      this.pcVisible = false
    },
    continueCall() {
      this.ifshowStatus = true
      setTimeout(()=>{
        this.ifshowStatus = false
      },1500)
    },
    closeScreen() {
      this.screenVisible = false
    },
    sureScreen() {
      this.screenVisible = false
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    register() {
      this.$router.push("/index")
    },
    getCode() {
      this.screenVisible = false
      this.pcVisible = true
    },
    goback() {
      this.$router.go(-1)
    },
    queryCurrentLile() {
      this.axios( 
        {
          method:'get',
          url:'/api/project/shareProjectDetail', 
          params: {
            shareProjectToken: this.token,
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
          this.shareProjectDetail = response.data.data
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    playVoice(url) {
      console.log(url)
      if(this.audio != null){
        this.audio.pause(); // 停止播放
        this.audio.currentTime = 0; // 重置播放时间
      }
      this.audio = null; // 释放音频对象
      this.audio = new Audio(url);
      this.audio.play();
    },
    goIndex() {
      this.$router.push("/index")
    },
    hideModel() {
      this.screenVisible = false
      this.pcVisible = false
    },
    // 滚动事件处理函数
    handleScroll(event) {
      // this.ifScreen = false
      const { scrollTop } = event.target;
      if(scrollTop > 125){
        this.ifShowTitle = true
      }else{
        this.ifShowTitle = false
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token
    this.queryCurrentLile()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .banner{
    background: url('../assets/image/top-bg.jpg') no-repeat center center / cover;
    width: 100%;
    height: 212px;
    z-index: 10000;
    /* background-attachment: fixed; */
  }
  .head-banner1{
    height: 60px;
    position: fixed;
    width: 100%;
    left: 0;
    top:0;
    overflow: hidden;
    z-index: 1000;
  }
  .head-banner2{
    background: url('../assets/image/top-bg-1.png') no-repeat center center / cover;
    height: 60px;
    position: fixed;
    width: 100%;
    left: 0;
    top:0;
    overflow: hidden;
    z-index: 1000;
  }
  /* .head-banner{
    background: url('../assets/image/top-bg-1.png') no-repeat center center / cover;
    height: 80px;
    position: fixed;
    width: 100%;
    left: 0;
    top:0;
    overflow: hidden;
    z-index: 99;
  } */
  .common-cdr{
    display: inline-block;
    padding: 0 8px;
    color: rgba(103, 110, 125, 1);
    line-height: 18px;
    margin-top:5px
  }
  .active-cdr{
    display: inline-block;
    padding: 0 8px;
    color: rgba(44, 54, 74, 1);
    font-weight: bold;
    background: #fff;
    height: 18px;
    line-height: 18px;
    border-radius: 6px;
  }
</style>
