<template>
    <div class="index">
        <div style="position: fixed;height: 64px;background: #fff;color: rgba(44, 54, 74, 1);line-height: 80px;top:0;left:0;width:100%;text-align: left;padding-left: 16px;">
            <span :class="currentTap==2?'active-tap':'normal-tap'" @click="selectTap(2)" style="margin-right:24px;position:relative">
                <span style="z-index: 2;">线索客户</span>
                <span v-if="currentTap==2" style="position: absolute;left:0;bottom:3px;display: inline-block;width:100%;height: 4px;background:rgba(120, 86, 255, 0.5);z-index: -1"></span>
            </span>
            <span :class="currentTap==1?'active-tap':'normal-tap'" @click="selectTap(1)" style="position:relative">
                <span style="z-index: 2;">收藏话单</span>
                <span v-if="currentTap==1" style="position: absolute;left:0;bottom:3px;display: inline-block;width:100%;height: 4px;background:rgba(120, 86, 255, 0.5);z-index: -1"></span>
            </span>
            <span :class="currentTap==3?'active-tap':'normal-tap'" @click="selectTap(3)" style="position:relative;margin-left:24px;">
                <span style="z-index: 2;">Bridge通话</span>
                <span v-if="currentTap==3" style="position: absolute;left:0;bottom:3px;display: inline-block;width:100%;height: 4px;background:rgba(120, 86, 255, 0.5);z-index: -1;"></span>
            </span>
        </div>
        <div v-if="currentTap==1" style="flex: 1;overflow: hidden;display: flex;flex-direction: column;padding:0 0 0 16px;">
            <div style="width: 100%;position: relative;padding-right: 16px;padding-top:5px;">
                <a-input v-model="searchKeyWord" :placeholder="'搜索'+totalNum+'条话单'" style="border:none;background:#fff;text-align: center;background:rgba(243, 245, 248, 1)"/>
                <a-icon @click="onSearch" type="search" style="color: rgba(0,0,0,.45);position: absolute;right:28px;top:14px;" />
            </div>
            <div style="margin-top:20px">
                <div @click="showCallList" style="display: inline-block;">
                    <span style="color:rgba(103, 110, 125, 1);font-size:13px;">全部话单</span>
                    <a-icon style="margin-left:2px;font-size:14px;" type="caret-down" />
                </div>
            </div>
            <div style="flex: 1;overflow-y: auto;margin-top:16px;padding-right:10px;" @scroll="handleScroll">
                <div @click="viewDel(item.id)" v-for="(item,index) in callRecordList" :key="index" style="height: 58px;margin-bottom: 16px;display: flex;">
                    <img style="width:32px;height:32px" src="../assets/image/user.png" alt="">
                    <div style="flex: 1;margin: 0 8px">
                        <div style="color:rgba(44, 54, 74, 1);font-size: 15px;font-weight: bold">{{item.name}}</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.phone}}</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px">{{item.projectName}}</div>
                    </div>
                    <div style="display: inline-block">
                        <div style="color:rgba(179, 184, 193, 1);font-size:12px;">{{item.callTime | sliceText(5)}}</div>
                        <div>
                            <span style="color:rgba(103, 110, 125, 1);font-size:13px;">{{item.duration}}</span>
                            <a-icon style="font-size: 16px;margin-left:10px;" type="caret-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="position: fixed;bottom:0;left:0;width: 100%;height: 56px;background: rgba(249, 249, 249, 1);padding-top: 6px;">
            <a-row style="text-align: center;">
            <a-col :span="8" >
                <div style="margin: 4px 0 0px 0;">
                    <img @click="goIndex" style="width:22px;" src="../assets/image/1-0.png" alt="">
                </div>
                <div class="tab-item">
                    <span @click="goIndex">群呼</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div style="margin: 4px 0 0px 0;">
                <img style="width:22px;" src="../assets/image/2-1.png" alt="">
                </div>
                <div class="tab-item" style="color:rgba(120, 86, 255, 1)">客户</div>
            </a-col>
            <a-col :span="8">
                <div style="margin: 4px 0 0px 0;">
                <img @click="goMine" style="width:22px;" src="../assets/image/3-0.png" alt="">
                </div>
                <div @click="goMine" class="tab-item">我的</div>
            </a-col>
            </a-row>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '40%' }" round >
            <van-picker
                title="选择机器人"
                show-toolbar
                :columns="senceNameList"
                @confirm="onConfirm"
                @cancel="onCancel"
                @change="onChange"
            />
        </van-popup>
        <div v-if="currentTap==2" style="flex: 1;overflow: hidden;display: flex;flex-direction: column;padding:0 16px 0 16px;">
            <div style="position: relative;padding-top:5px;">
                <a-input v-model="contactKeyWord" @change="queryContacts" style="background:rgba(243, 245, 248, 1);height:32px;border: none;" :placeholder="'输入名称/备注搜索'+contactNum+'个线索客户'" />
                <a-icon @click="queryContacts" type="search" style="color: rgba(0,0,0,.45);position: absolute;right:12px;top:14px;" />
            </div>
            <div style="margin-top:20px;">
                <van-popover
                    v-model="showPopover"
                    trigger="click"
                    :actions="actions"
                    @select="onSelect"
                    style="display: inline-block;margin-left: 16px;"
                    
                >
                    <template #reference>
                        <span style="color:rgba(103, 110, 125, 1);font-size:13px">{{starText}}</span>
                        <a-icon style="color:rgba(44, 52, 76, 1);font-size:14px;margin-left:4px;" type="caret-down" />
                    </template>
                </van-popover>
            </div>
            <div style="flex: 1;overflow-y: auto;margin-top:20px;">
                <van-index-bar>
                    <div v-for="(item,index) in contactList" :key="index" style="border-bottom: 1px solid rgba(201, 204, 211, 0.5);">
                        <van-index-anchor :index="item.code" />
                        <van-cell @click="viewContactDel(item1.id)" :title="item1.name" v-for="(item1,index1) in item.contactsList" :key="index1">
                            <img v-for="(item2,index2) in item1.level" :key="index2" style="width:20px;" src="../assets/image/star2.png" alt="">
                        </van-cell>
                    </div>
                </van-index-bar>
                <div v-if="contactList.length==0" style="text-align:center;;margin-top:70px;">
                    <img style="width:200px" src="../assets/image/no-data.png" alt="">
                </div>
            </div>
            <div @click="addUser" style="width: 52px;height:52px;border-radius: 26px;background:rgba(120, 86, 255, 1);position: fixed;right: 24px;bottom:80px;box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);text-align: center;line-height: 56px;">
                <a-icon style="color:rgba(255, 255, 255, 1);font-size:22px;" type="plus" />
            </div>
        </div>
        <div v-if="currentTap==3" style="flex: 1;overflow: hidden;display: flex;flex-direction: column;padding:0 16px 0 16px;">
            <div style="position: relative;padding-top:5px;">
                <a-input v-model="bridgeKeyWord" @change="queryBridge" style="background:rgba(243, 245, 248, 1);height:32px;border: none;"  :placeholder="'搜索'+contactNum+'个线索客户'" />
                <a-icon @click="queryBridge" type="search" style="color: rgba(0,0,0,.45);position: absolute;right:12px;top:14px;" />
            </div>
            <div style="margin-top:20px;">
                <van-popover
                    v-model="showPopover"
                    trigger="click"
                    :actions="callState"
                    @select="selectState"
                    style="display: inline-block;margin-left: 16px;"
                    
                >
                    <template #reference>
                        <span style="color:rgba(103, 110, 125, 1);font-size:13px">{{statusText}}</span>
                        <a-icon style="color:rgba(44, 52, 76, 1);font-size:14px;margin-left:4px;" type="caret-down" />
                    </template>
                </van-popover>
            </div>
            <div style="flex: 1;overflow-y: auto;margin-top:16px;" @scroll="handleScroll">
                <div style="display:flex;margin-bottom:16px;" v-for="(item,index) in 0" :key="index">
                    <img src="../assets/image/user.png" style="width:32px;height:32px;" alt="">
                    <div style="flex:1;margin-left:8px;">
                        <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;">张三</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">15000000000</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">机器人名称</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">15000000000</div>
                    </div>
                    <div style="text-align:right;">
                        <div style="color:rgba(179, 184, 193, 1);font-size:10px;line-height:20px;">07.15  19:25</div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">13分28秒</div>
                        <div style="text-align:center">
                            <img src="../assets/image/b.png" style="width:11px;height:16px;" alt="">
                        </div>
                        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">3分28秒</div>
                    </div>
                    <img src="../assets/image/sanjiao.png" style="width:10px;height:14px;opacity: 0.3;margin-left:8px;margin-top:24px;" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    computed: {
      
    },
    data() {
        return {
            show: false,
            columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
            senceNameList: [],
            senceIdList: [],
            selectId: '',
            searchKeyWord: '',
            callRecordList: [],
            currentPage: 1,
            totalNum: 0,
            currentTap: 2,
            contactList: [],
            contactKeyWord: '',
            contactNum: 0,
            showPopover: false,
            // 通过 actions 属性来定义菜单选项
            actions: [
                { 
                    text: '所有等级',
                    id: ''
                }, 
                { 
                    text: '1星',
                    id: 1 
                }, 
                { 
                    text: '2星',
                    id: 2 
                }, 
                { 
                    text: '3星',
                    id: 3 
                }, 
                { 
                    text: '4星',
                    id: 4
                }, 
                { 
                    text: '5星',
                    id: 5 
                }, 
            ],
            starText: '客户等级',
            starId: '',
            bridgeKeyWord: '',
            bridgeNum: 0,
            callState: [
                { 
                    text: '全部状态',
                    id: 1 
                }, 
                { 
                    text: '连线超时',
                    id: 2 
                }, 
                { 
                    text: '连线成功',
                    id: 3 
                }, 
            ],
            statusText:' 全部状态',
            stateId: ''
        }
    },
    filters: {
        sliceText(value, length) {
        return value.slice(length); // 使用 slice() 方法去掉前几个字符
        },
    },
    methods: {
        onSearch() {
            this.currentPage = 1
            this.queryCallLog()
        },
        goIndex() {
            this.$router.push("/index")
        },
        showCallList() {
            this.show = true;
        },
        onConfirm(value, index) {
            this.show = false
            this.selectId = this.senceIdList[index]
            this.currentPage = 1
            this.queryCallLog()
        },
        onChange(picker, value, index) {
            console.log(`当前值：${value}, 当前索引：${index}`);
            // this.show = false
        },
        onCancel() {
            this.show = false
        },
        querySence() {
            this.axios( 
                {
                    method:'get',
                    url:'/api/project/userProjectList', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    // this.senceList = response.data.data
                    let arr = response.data.data
                    for(let i = 0; i < arr.length; i++){
                        this.senceNameList.push(arr[i]['name'])
                        this.senceIdList.push(arr[i]['projectId'])
                    }
                }else{
                    this.$toast(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        viewDel(id) {
            // this.$router.push("/callDel")
            this.$router.push({
                path: '/callDel',
                query:{
                    id: id,
                }
            });
        },
        queryCallLog() {
            this.axios( 
                {
                    method:'get',
                    url:'/api/callData/collectList', 
                    params: {
                        current: this.currentPage,
                        size: 10,
                        projectId: this.selectId,
                        keyword: this.searchKeyWord
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.totalNum = response.data.data.total
                    if(this.currentPage == 1){
                        this.callRecordList = response.data.data.records
                    }else{
                        this.callRecordList = this.callRecordList.concat(response.data.data.records)
                    }
                }else{
                    // this.$message.error(response.data.userTip);
                    this.$toast(response.data.userTip)
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        // 滚动事件处理函数
        handleScroll(event) {
            // this.ifScreen = false
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            // console.log('scrollTop--'+scrollTop)
            // console.log('scrollHeight--'+ scrollHeight)
            // console.log('clientHeight--'+clientHeight)
            // 当滚动到底部时，距离顶部+自身高度 >= 总高度 - 一定的阈值
            if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
                if(this.totalNum != this.callRecordList.length){
                    this.currentPage ++
                    this.queryCallLog()
                } 
            }
        },
        goMine() {
            // this.$toast('内测阶段，暂未开放')
            this.$router.push("/mine")
        },
        showContact() {
            this.$toast('内测阶段，暂未开放')
        },
        selectTap(num) {
            this.currentTap = num
        },
        viewContactDel(id) {
            this.$router.push({
                path: '/contactDel',
                query:{
                    id: id,
                }
            });
        },
        addUser() {
            this.$router.push("/editContact")
        },
        queryContacts() {
            this.axios( 
                {
                    method:'get',
                    url:'/api/contacts/contactsList', 
                    params:{
                        keyword: this.contactKeyWord,
                        level: this.starId
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    console.log(response.data.data)
                    this.contactList = response.data.data
                    let num = 0
                    for(let i = 0; i < this.contactList.length; i++){
                        for(let j = 0; j < this.contactList[i]['contactsList'].length; j++){
                            num = num + 1
                        }
                    }
                    this.contactNum = num
                }else{
                    this.$toast(response.data.userTip)
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        onSelect(action) {
            console.log(action.id)
            this.starText = action.text
            this.starId = action.id
            this.queryContacts()
        },
        queryBridge() {

        },
        selectState(action) {
            this.statusText = action.text
            this.stateId = action.id
            this.queryBridge()
        }
    },
    mounted() {
        if (localStorage.getItem('token') ==null){
            this.$router.push("/index")
        }else{
            this.querySence()
            this.queryCallLog()
            this.queryContacts()
        }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .index{
      width: 100%;
      height: 100%;
      position: relative;
      padding: 64px 0 56px 0;
      background: #fff;
      display: flex;
      flex-direction: column;
    }
    .tab-item{
      color: rgba(103, 110, 125, 1);
      margin-top:2px;
      font-size: 10px;
      font-weight: bold;
    }
    .active-tap{
        font-weight: bold;
        font-size:20px;
    }
    .normal-tap{
        color:rgba(103, 110, 125, 1);
        font-size:16px;
    }
    .van-cell::after{
        border-bottom: none !important;
    }
  </style>
  