import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router/router.js'
import Router from 'vue-router'
import http from './router/http'
import store from './store/store.js';
import Vant from 'vant';
import 'vant/lib/index.css';
import {localData, sessionData} from "../src/storage/storage"
import { IndexBar, IndexAnchor } from 'vant';
import { Popover } from 'vant';
Vue.use(Antd);
Vue.use(Vant);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.prototype.axios = http
Vue.use(Popover);

Vue.config.productionTip = false
Vue.prototype.localData = localData;
Vue.prototype.sessionData=sessionData;
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
