<template>
  <div class="index">
    <div style="position: fixed;background:rgba(249, 249, 249, 1);width:100%;left:0;top:0;padding: 27px 16px 12px;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">充值</span>
    </div>
    <div style="margin-top:30px;color:rgba(44, 54, 74, 1);font-size:24px;font-weight:bold;padding:0 16px;">
      ￥{{amount}}
    </div>
    <div style="color:rgba(103, 110, 125, 1);font-size:12px;padding:0 20px 25px;">当前余额</div>
    <div style="flex:1;background:#fff;border-radius:16px 16px 0 0;padding:20px 17px 23px;">
      <div>
        <span :class="currentTap==1?'active-tap':'normal-tap'" @click="selectTap(1)" style="margin-right:24px;position:relative">
          <span>现金充值</span>
          <span v-if="currentTap==1" style="position: absolute;left:0;bottom:2px;display: inline-block;width:100%;height: 2px;background:rgba(120, 86, 255, 1);"></span>
        </span>
        <span :class="currentTap==2?'active-tap':'normal-tap'" @click="selectTap(2)" style="position:relative">
          <span>卡券充值</span>
          <span v-if="currentTap==2" style="position: absolute;left:0;bottom:2px;display: inline-block;width:100%;height: 2px;background:rgba(120, 86, 255, 1);"></span>
        </span>
      </div>
      <div v-if="currentTap==1">
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:24px;">输入充值金额</div>
        <div style="display:flex;margin-top:9px;">
          <a-input placeholder="请输入金额" style="flex:1;height:52px;border:none;background:rgba(179, 184, 193, 0.16);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:17px;font-weight:bold;" />
          <span style="color:rgba(44, 54, 74, 1);font-size:15px;margin-left:12px;line-height:52px;">元</span>
        </div>
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:24px;">选择支付方式</div>
        <div style="margin-top:9px;">
          <a-row>
            <a-col :span="12" style="padding-right:7px;">
              <div @click="selectPay(1)" :class="selectedPay==1?'active-pay':'normal-pay'" style="height:52px;border-radius:8px;line-height:52px;padding-left:13px;">
                <img src="../assets/image/weixin.png" style="width:32px;" alt="">
                <span style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;margin-left:9px;">微信</span>
              </div>
            </a-col>
            <a-col :span="12" style="padding-left:7px;">
              <div @click="selectPay(2)" :class="selectedPay==2?'active-pay':'normal-pay'" style="height:52px;border-radius:8px;line-height:52px;padding-left:13px;">
                <img src="../assets/image/zhifubao.png" style="width:32px;" alt="">
                <span style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;margin-left:9px;">支付宝</span>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div v-if="currentTap==2">
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:24px;">输入卡号</div>
        <a-input v-model="cardNum" placeholder="请输入卡号" style="height:52px;border:none;background:rgba(179, 184, 193, 0.16);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:15px;margin-top:9px;" />
        <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:24px;">输入卡密</div>
        <a-input v-model="cardPwd" placeholder="请输入卡密" style="height:52px;border:none;background:rgba(179, 184, 193, 0.16);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:15px;margin-top:9px;" />
      </div>
      <div style="position:fixed;width:100%;left:0;bottom:24px;padding:0 16px;">
        <div @click="charge" style="height:40px;line-height:40px;background:rgba(120, 86, 255, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);border-radius:8px;color:#fff;font-size:15px;text-align:center">充值</div>
      </div>
    </div>
    <van-action-sheet v-model="ifAuthentication" title="" close-on-click-action :close-on-click-overlay='false'>
      <div v-if="authenticationRes==1">
        <div style="text-align: center;margin:80px 0 35px;">
          <a-icon style="font-size:25px;" type="loading" />
        </div>
        <div style="color:rgba(164, 164, 164, 1);font-size:14px;text-align: center;margin-bottom: 82px;">正在充值，请耐心等待...</div>
      </div>
      <div v-if="authenticationRes==2" style="text-align:center;">
        <div style="margin:58px 0 24px">
          <img style="width:80px;" src="../assets/image/auth-fail.svg" alt="">
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:24px;font-weight:bold;">充值失败</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:14px;margin:8px 0 60px;">{{failMsg}}</div>
        <div style="padding:0 20px 24px;">
          <div @click="continueCharge" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">继续充值</div>
        </div>
      </div>
      <div v-if="authenticationRes==3" style="text-align:center;">
        <div style="margin:58px 0 24px">
          <img style="width:80px;" src="../assets/image/auth-success.svg" alt="">
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:24px;font-weight:bold;margin-bottom:8px;">充值成功</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:14px;margin:8px 0 60px;">{{successMsg}}</div>
        <div style="padding:0 20px 24px;">
          <div @click="getKnown" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">我知道了</div>
        </div>
      </div>

    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        currentTap: 1,
        selectedPay: '',
        authenticationRes: 2,
        failMsg: '失败原因解释失败原因解释',
        ifAuthentication: false,
        successMsg: '',
        amount: 0,
        cardNum: '',
        cardPwd: '',
      }
  },
  methods: {
    goback() {
      this.$router.go(-1); 
    },
    selectTap(num) {
      this.currentTap = num
    },
    selectPay(type) {
      this.selectedPay = type
    },
    charge() {
      if(this.currentTap == 2){
        this.chargeMoney()
      }else{
        this.$toast('您的账户暂不支持现金充值，请使用卡券充值')
      }
    },
    continueCharge() {
      this.cardNum = ''
      this.cardPwd = ''
      this.ifAuthentication = false
    },
    getKnown() {
      this.ifAuthentication = false
      this.$router.push("/mine")
    },
    queryMoney() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/amount', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.amount = response.data.data.amount
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    chargeMoney() {
      this.authenticationRes = 1
      this.ifAuthentication = true
      this.axios.post('/api/rechargeCard/recharge', {
        number: this.cardNum,
        password: this.cardPwd,
      })
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
          if(response.data.data.status==1){
            this.authenticationRes = 3
            this.successMsg = response.data.data.denomination+'元已打入您的账户'
          }else if(response.data.data.status==2){
            this.authenticationRes = 2
            this.failMsg = response.data.data.msg
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    }
  },
  mounted() {
    this.queryMoney()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 0 0;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
  .active-tap{
    font-weight: bold;
    font-size:20px;
  }
  .normal-tap{
    color:rgba(103, 110, 125, 1);
    font-size:16px;
  }
  .van-cell::after{
    border-bottom: none !important;
  }
  .active-pay{
    background: rgba(120, 86, 255, 0.12);
  }
  .normal-pay{
    background: rgba(179, 184, 193, 0.16);
  }
</style>
