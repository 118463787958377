<template>
  <div class="index">
    <div style="background:rgba(249, 249, 249, 1);padding: 27px 16px 0 0;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">充值记录</span>
    </div>
    <div style="padding:12px 16px 24px;background:#fff;border-radius:12px;margin-top:17px;flex:1;overflow-y:auto;">
      <div style="background:#fff;border-radius: 12px;height:100%;display:flex;flex-direction:column;">
        <div style="width:100%;height: 100%;position: relative;height: 60px;">
          <!-- <a-date-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:0;" /> -->
          <a-month-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:0;"  />
          <div v-if="month!=''" style="position: absolute;top:0px;left:0px;font-size:20px;z-index: 1;">
            <span style="color:rgba(44, 54, 74, 1);font-size:40px;font-weight: bold;">{{month}}</span>
            <span style="color:rgba(44, 54, 74, 1);font-size:16px;font-weight:bold;margin:0 11px 0 6px">月</span>
            <a-icon type="caret-down" />
            <span style="color:rgba(179, 184, 193, 1);font-size:12px;margin-left:16px;">{{year}}</span>
          </div>
          <div v-if="month==''" style="position: absolute;top:0px;left:0px;font-size:16px;z-index: 1;color:#ccc">请选择日期</div>
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:13px;margin-top:11px;">
          总计充值  ¥{{totalNum}}
        </div>
        <div style="height:1px;background:rgba(201, 204, 211, 0.5);margin:20px 0"></div>
        <div v-if="smsList.length!=0" style="overflow-y: auto;flex: 1;">
          <div style="background:#fff;display:flex;margin-bottom:21px;" v-for="(item,index) in smsList" :key="index">
            <img v-if="item.type=='现金充值'" src="../assets/image/charge.png" style="width:32px;height:32px;" alt="">
            <img v-if="item.type=='卡券充值'" src="../assets/image/quan.png" style="width:32px;height:32px;" alt="">
            <div style="flex:1;margin:0 0 0 8px;">
              <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;width:100%;">
                <span style="float:left;margin-top:2px;">{{item.type}}</span>
                <span style="color:rgba(44, 54, 74, 1);font-size:18px;font-weight:bold;float:right;margin-top:1px;">+{{item.amount}}</span>
              </div>
              <div style="clear:both;"></div>
              <div style="color:rgba(103, 110, 125, 1);font-size:12px">{{item.remark}}</div>
              <div style="color:rgba(179, 184, 193, 1);font-size:12px;">{{item.rechargeTime}}</div>
            </div>
          </div>
        </div>
        <div v-if="smsList.length==0" style="text-align: center;">
          <img style="width:40%;margin-top:50px;" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        locale,
        zhCN,
        year: '',
        month: '',
        day: '',
        smsList: [],
        current: 1,
        totalNum: 0,
      }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    onChange(date, dateString) {
      let str = dateString.split("-")
      console.log(str)
      this.year = str[0]
      this.month = str[1]
      this.day = str[2]
      this.querySmsList()
    },
    querySmsList() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/rechargeRecordList', 
          params: {
            rechargeMonth: this.year+'-'+this.month
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.totalNum = response.data.data.totalAmount
          this.smsList = response.data.data.rechargeRecordList
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
          if(this.totalNum != this.smsList.length){
              this.current ++
              this.querySmsList()
          } 
      }
    },
  },
  mounted() {
    const today = new Date(); // 创建一个新的 Date 对象
    this.year = today.getFullYear(); // 获取年份
    this.month = (today.getMonth() + 1)<10?'0'+(today.getMonth() + 1):(today.getMonth() + 1); // 获取月份（0-11），所以要加1
    // this.day = today.getDate()<10?'0'+today.getDate():today.getDate(); // 获取日期（1-31）
    this.querySmsList()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px 16px 32px;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
</style>
