<template>
  <div class="index">
    <div style="background:rgba(249, 249, 249, 1);padding: 27px 16px 0 0;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">报表账单</span>
    </div>
    <div style="padding:12px 0 24px;border-radius:12px;margin-top:17px;flex:1;overflow-y:auto;">
      <div style="border-radius: 12px;height:100%;display:flex;flex-direction:column;">
        <div style="width:100%;height: 100%;position: relative;height: 60px;">
          <a-date-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:0;" />
          <div v-if="month!=''" style="position: absolute;top:0px;left:0px;font-size:20px;z-index: 1;">
            <span style="color:rgba(44, 54, 74, 1);font-size:40px;font-weight: bold;">{{month}}</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 14px;margin:0 8px">/</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 16px;font-weight: bold;margin-right:6px;">{{ day }}</span>
            <a-icon type="caret-down" />
          </div>
          <div style="display:inline-block;width:108px;height:28px;border-radius:8px;background:#E4E5E8;position:absolute;top:20px;right:0px;font-size:12px;padding:3px 4px 0">
            <span @click="selectAccount(1)" :class="selectedTap==1?'active-tap':'normal-tap'">我的</span>
            <span @click="selectAccount(2)" :class="selectedTap==2?'active-tap':'normal-tap'">全部</span>
          </div>
          <div v-if="month==''" style="position: absolute;top:0px;left:0px;font-size:16px;z-index: 1;color:#ccc">请选择日期</div>
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:13px;margin-top:11px;margin-bottom:16px;">
          总计消费  ¥{{totalNum}}
        </div>
        <div v-if="smsList.length!=0" style="overflow-y: auto;flex: 1;">
          <div @click="viewReportDel(item.project_id,item.user_id,item.projectName,item.avatar)" style="background:#fff;display:flex;margin-bottom:16px;height:74px;padding:18px 16px 0;border-radius:12px;" v-for="(item,index) in smsList" :key="index">
            <img :src="item.avatar" style="width:36px;height:36px;border-radius:18px;margin-top:2px;" alt="">
            <div style="flex:1;margin:0 0 0 8px;">
              <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;width:100%;">
                <span style="margin-top:2px;">{{item.projectName}}</span>
              </div>
              <div style="color:rgba(103, 110, 125, 1);font-size:12px">{{item.userName}}</div>
            </div>
            <div style="color:rgba(44, 54, 74, 1);font-size:18px;font-weight:bold;margin-top:8px;">-{{item.price}}</div>
          </div>
        </div>
        <div v-if="smsList.length==0" style="text-align: center;">
          <img style="width:40%;margin-top:50px;" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        locale,
        zhCN,
        year: '',
        month: '',
        day: '',
        smsList: [],
        current: 1,
        totalNum: 0,
        selectedTap: 1,
      }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    onChange(date, dateString) {
      let str = dateString.split("-")
      console.log(str)
      this.year = str[0]
      this.month = str[1]
      this.day = str[2]
      this.querySmsList()
    },
    querySmsList() {
      this.axios( 
        {
          method:'get',
          url:'/api/bill/list', 
          params: {
            type: this.selectedTap,
            date: this.year+'-'+this.month+'-'+this.day
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.totalNum = response.data.data.totalAmount
          this.smsList = response.data.data.billList
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
          if(this.totalNum != this.smsList.length){
              this.current ++
              this.querySmsList()
          } 
      }
    },
    selectAccount(type) {
      this.selectedTap = type
      this.querySmsList()
    },
    viewReportDel(project_id,user_id,project_name,avatar) {
      this.$router.push({
        path: '/reportDel',
        query:{
          project_id: project_id,
          user_id: user_id,
          year: this.year,
          month: this.month,
          day: this.day,
          project_name: project_name,
          avatar: avatar
        }
      });
    }
  },
  mounted() {
    const today = new Date(); // 创建一个新的 Date 对象
    this.year = today.getFullYear(); // 获取年份
    this.month = (today.getMonth() + 1)<10?'0'+(today.getMonth() + 1):(today.getMonth() + 1); // 获取月份（0-11），所以要加1
    this.day = today.getDate()<10?'0'+today.getDate():today.getDate(); // 获取日期（1-31）
    this.querySmsList()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0px 16px 32px;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
  .active-tap{
    display: inline-block;
    width: 48px;
    height: 22px;
    line-height: 22px;
    font-weight: bold;
    background:#fff;
    border-radius: 6px;
    text-align: center;
  }
  .normal-tap{
    display: inline-block;
    width: 48px;
    height: 22px;
    line-height: 22px;
    background:#E4E5E8;
    border-radius: 6px;
    text-align: center;
  }
</style>
