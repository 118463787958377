<template>
  <div class="index">
    <div style="height: 60px;display: flex;position: relative;">
      <img v-if="userInfo.avatar==''" style="width:60px;height:60px;border-radius: 30px;opacity: 0.5;" src="../assets/image/user2.png" alt="">
      <img v-if="userInfo.avatar!=''" style="width:60px;height:60px;border-radius: 30px;opacity: 0.5;" :src="userInfo.avatar" alt="">
      <div style="flex: 1;margin-left: 10px;">
        <div @click="realNameCheck(userInfo.isCertification)" style="color:rgba(44, 54, 74, 1);font-size:20px;font-weight: bold;margin-top:5px;">{{userInfo.name}}</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{userInfo.phone}}</div>
      </div>
      <div>
        <img @click="goKeFu" src="../assets/image/kefu.svg" style="width:20px;height:20px;" alt="">
        <div @click="goKeFu" style="color:rgba(44, 54, 74, 1);font-size:10px;">客服</div>
      </div>
    </div>
    <div style="height: 92px;background:#fff;border-radius: 12px;margin-top:17px;padding: 14px 16px;">
      <div style="color:rgba(103, 110, 125, 1);font-size:12px;">推广余额</div>
      <div style="display: flex;margin-top:10px;">
        <img style="width:36px;height:36px;" src="../assets/image/money.png" alt="">
        <span style="color:rgba(44, 54, 74, 1);font-size:24px;font-weight: bold;flex:1;margin-left:4px;">{{amount}}</span>
        <span @click="chargeMoney" style="display: inline-block;width:64px;height: 32px;line-height: 32px;text-align: center;border-radius: 6px;background:rgba(120, 86, 255, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);color:#fff">充值</span>
      </div>
    </div>
    <div style="height: 112px;margin-top:12px;background:#fff;border-radius: 12px;padding: 14px 0;">
      <a-row>
        <a-col :span="8" @click="chargeRecode">
          <div style="text-align: center;">
            <span>充值记录</span>
            <img style="width:12px;height:12px;margin-left:6px;" src="../assets/image/right.png" alt="">
          </div>
          <div style="text-align: center;margin-top:10px;">
            <img style="width:52px;" src="../assets/image/chongzhi.png" alt="">
          </div>
        </a-col>
        <a-col :span="8" @click="goAccount">
          <div style="text-align: center;">
            <span>账号报表</span>
            <img style="width:12px;height:12px;margin-left:6px;" src="../assets/image/right.png" alt="">
          </div>
          <div style="text-align: center;margin-top:10px;">
            <img style="width:52px;" src="../assets/image/account-forms.png" alt="">
          </div>
        </a-col>
        <a-col :span="8" @click="notOpen">
          <div style="text-align: center;">
            <span>线路合作</span>
            <img style="width:12px;height:12px;margin-left:6px;" src="../assets/image/right.png" alt="">
          </div>
          <div style="text-align: center;margin-top:10px;">
            <img style="width:52px;" src="../assets/image/xianlu.png" alt="">
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="height: 46px;margin-top:12px;background:#fff;padding:13px 16px;border-radius: 12px;">
      <span style="color:rgba(44, 54, 74, 1);font-size:14px;font-weight: bold;">我的分享</span>
      <div style="float: right;">
        <a-icon @click="goShare" type="share-alt" style="color:rgba(120, 86, 255, 1);font-size:18px;margin-right:8px;margin-top:4px;float: left;" />
        <a-icon type="right" style="color:rgba(4, 4, 4, 1);font-size:14px;float: right;margin-top:6px;" />
      </div>
    </div>
    <div style="height: 258px;background:#fff;margin-top:12px;padding:14px 16px;border-radius: 12px;">
      <div>
        <span style="color:rgba(103, 110, 125, 1);font-size:12px;">挂机短信_电子名片</span>
        <a-icon @click="goEndMsg" style="float: right;margin-top:2px;" type="right" />
      </div>
      <div style="height: 162px;background:rgba(243, 245, 248, 1);border-radius: 11px;margin-top: 12px;padding:19px 14px 0 16px;position: relative">
        <div style="color:rgba(44, 54, 74, 1);font-size:18px;font-weight: bold;height: 27px;">{{hangUpInfo.cardName}}</div>
        <div @click="goSet" style="position: absolute;right:14px;top: 14px;color:rgba(120, 86, 255, 1);text-align: center">
          <a-icon style="font-size:20px;" type="setting" />
          <div style="font-weight: bold;font-size:12px;">设置</div>
        </div>
        <div>
          <span style="color:rgba(44, 54, 74, 1);font-size:14px;font-weight: bold;">{{hangUpInfo.cardPhone}}</span>
          <img style="width: 20px;margin-left:9px;" src="../assets/image/phone.png" alt="">
        </div>
        <div style="color:rgba(103, 110, 125, 1);font-size:12px;margin-top:16px;display: flex;">
          <span style="display: inline-block;width:48px;">微信号：</span>
          <span style="flex:1">{{hangUpInfo.cardWechat}}</span>
        </div>
        <div style="color:rgba(103, 110, 125, 1);font-size:12px;margin-top:4px;display: flex;">
          <span style="display: inline-block;width:48px;">简介：</span>
          <span style="flex:1">{{hangUpInfo.cardDescription}}</span>
        </div>
      </div>
      <div style="margin-top:16px;">
        <a-row>
          <a-col :span="12">
            <div>
              <span style="color:rgba(179, 184, 193, 1);font-size:12px;">今日短信发送：</span>
              <span style="color:rgba(44, 54, 74, 1);font-size:14px;font-weight: bold;">{{hangUpInfo.todaySmsCount}}</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div>
              <span style="color:rgba(179, 184, 193, 1);font-size:12px;">今日短信访客：</span>
              <span style="color:rgba(44, 54, 74, 1);font-size:14px;font-weight: bold;">{{hangUpInfo.todayVisitorCount}}</span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div style="width:100%;margin-top:16px;">
      <div @click="loginOut" style="height: 40px;text-align: center;line-height: 40px;color:rgba(103, 110, 125, 1);font-size:14px;border-radius: 12px;background:rgba(255, 255, 255, 1);">退出登录</div>
    </div>
    <div style="height:100px;width:100%"></div>
    <div style="position: fixed;bottom:0;left:0;width: 100%;height: 56px;background: rgba(255, 255, 255, 1);padding-top: 6px;border-top:1px solid rgba(179, 184, 193, 0.16)">
      <a-row style="text-align: center;">
        <a-col :span="8" >
            <div style="margin: 4px 0 0px 0;">
                <!-- <a-icon @click="goIndex" type="phone" style="font-size:18px;transform: rotate(90deg);" /> -->
                <img @click="goIndex" style="width:22px;" src="../assets/image/1-0.png" alt="">
            </div>
            <div class="tab-item">
                <span @click="goIndex">群呼</span>
            </div>
        </a-col>
        <a-col :span="8">
            <div style="margin: 4px 0 0px 0;">
            <!-- <a-icon type="user" style="font-size:18px;color:rgba(120, 86, 255, 1);" /> -->
            <img @click="goContact" style="width:22px;" src="../assets/image/2-0.png" alt="">
            </div>
            <div @click="goContact" class="tab-item">客户</div>
        </a-col>
        <a-col :span="8">
            <div style="margin: 4px 0 0px 0;">
            <!-- <a-icon type="user" style="font-size:18px" /> -->
            <img style="width:22px;" src="../assets/image/3-1.png" alt="">
            </div>
            <div class="tab-item" style="color:rgba(120, 86, 255, 1)">我的</div>
        </a-col>
      </a-row>
    </div>
    <van-action-sheet v-model="ifShowAgreement" title="用户协议">
      <div style="margin-top:0px;padding:0 20px;max-height:200px;overflow-y: auto;">
        <div style="color:rgba(44, 54, 74, 1);font-size:12px;">
          授权提醒<br>
          提示您注意，为实现核实您真实身份并完成实名认证的目的，您需要向我们提供您的真实姓名、身份证号码以及号码信息。我们承诺此类信息将不会用于任何其他您未授权的场景，并且会对此类信息采取加密等安全保护技术措施。<br>
          请您理解，为实现上述目的，我们会将您的上述实名信息共享至第三方合作伙伴[阿里云]，我们会与对方签署严格的保密协议保障您的信息安全，在实名认证场景完成后，我们会对在本场景下收集的上述信息做删除或匿名化处理。您点击“同意"即视为您同意向我们提供上述信息,并同意我们将您的实名信息传输给第三方。<br>
          依据工信部规定，为了遏制垃圾短信和电话诈骗，要求用户在使用/办理‌固定电话、‌移动电话、宽带业务等时提供真实的身份信息，完成实名认证，以确保通信服务的合法性和安全性；‌<br>
          用户身份证号码，目前支持一代/二代身份证，号码必须为18位或15位，末尾为x的需要大写为X；<br>
          用户真实姓名，以身份证上姓名为准。
        </div>
      </div>
      <div style="padding:0 20px 24px;margin-top:16px;">
        <div @click="notAgree" style="height: 40px;background:#fff;border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:rgba(246, 76, 79, 1);">不同意</div>
        <div @click="agreeProgramme" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">同意</div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="ifRealName" title="实名认证">
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;padding-left:20px;">输入本人手机号【运营商实名】</div>
      <div style="display:flex;margin-top:9px;padding:0 20px;">
        <a-input v-model.trim="phoneNum" type="number" placeholder="请输入手机号码" style="flex:1;border:none;background:rgba(179, 184, 193, 0.16);height:40px;" />
        <span @click="getCode" style="display:inline-block;width:108px;height:40px;text-align:center;line-height:40px;border-radius:8px;color:#fff;background:rgba(120, 86, 255, 1);margin-left:15px;font-size:15px;">获取验证码</span>
      </div>
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;padding-left:20px;margin-top:20px;">输入验证码</div>
      <a-row style="text-align: center;padding:0 12px;margin-top:9px;">
        <a-col :span="4">
          <a-input ref="myInput1"  @input="onInputChange1" v-model="code1" type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
        <a-col :span="4">
          <a-input ref="myInput2"  @input="onInputChange2" v-model="code2" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
        <a-col :span="4">
          <a-input ref="myInput3"  @input="onInputChange3" v-model="code3" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
        <a-col :span="4">
          <a-input ref="myInput4"  @input="onInputChange4" v-model="code4" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
        <a-col :span="4">
          <a-input ref="myInput5"  @input="onInputChange5" v-model="code5" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
        <a-col :span="4">
          <a-input ref="myInput6" v-model="code6" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
        </a-col>
      </a-row>
      <div style="padding:0 20px 24px;margin-top:104px;">
        <div @click="checkVerifyCode" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">下一步</div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="ifNameCheck" title="实名认证">
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;padding-left:20px;">输入姓名</div>
      <div style="padding:0 20px;margin-top:9px;">
        <a-input v-model="authName" placeholder="请输入姓名" style="border:none;background:rgba(179, 184, 193, 0.16);height:40px;" />
      </div>
      <div style="color:rgba(103, 110, 125, 1);font-size:13px;padding-left:20px;margin-top:20px;">输入身份证号</div>
      <div style="padding:0 20px;margin-top:9px;">
        <a-input v-model="authIdcard" placeholder="请输入身份证号" style="border:none;background:rgba(179, 184, 193, 0.16);height:40px;" />
      </div>
      <div style="color:rgba(179, 184, 193, 1);font-size:12px;margin-top:16px;padding:0 20px;">
        <img style="width:16px;margin-right:8px;float:left" src="../assets/image/warning.svg" alt="">
        <span>提示：手机号/姓名/身份证实名信息确保一致</span>
      </div>
      <div style="padding:0 20px 24px;margin-top:104px;">
        <div @click="sureSubmit" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">确认提交</div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="ifAuthentication" title="实名认证" close-on-click-action @cancel="onCancel" :close-on-click-overlay='false'>
      <div v-if="authenticationRes==1">
        <div style="text-align: center;margin:80px 0 35px;">
          <a-icon style="font-size:25px;" type="loading" />
        </div>
        <div style="color:rgba(164, 164, 164, 1);font-size:14px;text-align: center;margin-bottom: 82px;">正在认证，请耐心等待...</div>
      </div>
      <div v-if="authenticationRes==2" style="text-align:center;">
        <div style="margin:58px 0 24px">
          <img style="width:80px;" src="../assets/image/auth-fail.svg" alt="">
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:24px;font-weight:bold;">认证失败</div>
        <div style="color:rgba(103, 110, 125, 1);font-size:14px;margin:8px 0 60px;">{{failMsg}}</div>
      </div>
      <div v-if="authenticationRes==3" style="text-align:center;">
        <div style="margin:58px 0 24px">
          <img style="width:80px;" src="../assets/image/auth-success.svg" alt="">
        </div>
        <div style="color:rgba(44, 54, 74, 1);font-size:24px;font-weight:bold;margin-bottom:90px;">认证成功</div>
      </div>
      <div style="padding:0 20px 24px;">
        <div @click="getInfo" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;margin-top:12px">确认</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
          show: false,
          columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
          senceNameList: [],
          senceIdList: [],
          selectId: '',
          searchKeyWord: '',
          callRecordList: [],
          currentPage: 1,
          totalNum: 0,
          currentTap: 1,
          userInfo: '',
          hangUpInfo: '',
          amount: 0,
          ifShowAgreement: false,
          ifRealName: false,
          code1: '',
          code2: '',
          code3: '',
          code4: '',
          code5: '',
          code6: '',
          ifNameCheck: false,
          ifAuthentication: false,
          authenticationRes: 1,
          phoneNum: '',
          authName: '',
          authIdcard: '',
          failMsg: ''
      }
  },
  methods: {
    goIndex() {
        this.$router.push("/index")
    },
    goContact() {
        // this.$toast('内测阶段，暂未开放')
        this.$router.push("/contacts")
    },
    showContact() {
        this.$toast('内测阶段，暂未开放')
    },
    selectTap(num) {
        this.currentTap = num
    },
    goSet() {
      this.$router.push({
        path: '/endSet',
        query:{
          cardName: this.hangUpInfo.cardName,
          cardPhone: this.hangUpInfo.cardPhone,
          cardWechat: this.hangUpInfo.cardWechat,
          cardDescription: this.hangUpInfo.cardDescription,
        }
      });
    },
    goEndMsg() {
      // this.$router.push("/endMsg")
      this.$router.push({
        path: '/endMsg',
        query:{
          hangUpSms: this.hangUpInfo.hangUpSms,
        }
      });
    },
    queryUserInfo() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/userInfo', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.userInfo = response.data.data
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    queryHandUpInfo() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/hangUpCard', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          console.log(response.data.data)
          this.hangUpInfo = response.data.data
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    queryMoney() {
      this.axios( 
        {
          method:'get',
          url:'/api/user/amount', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.amount = response.data.data.amount
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    notOpen() {
      this.$toast('内测阶段，暂未开放')
    },
    goKeFu() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc009eca4d2527ca4e';
    },
    loginOut() {
      localStorage.removeItem('token')
      this.$router.push("/index")
    },
    realNameCheck(data) {
      if(data == 0){ 
        this.ifShowAgreement = true
      }
    },
    notAgree() {
      this.ifShowAgreement = false
    },
    agreeProgramme() {
      this.ifShowAgreement = false
      this.ifRealName = true
    },
    onInputChange1() {
      if(this.code1.length == 1){
        this.$refs.myInput2.focus()
      }
    },
    onInputChange2() {
      if(this.code2.length == 1){
        this.$refs.myInput3.focus()
      }
    },
    onInputChange3() {
      if(this.code3.length == 1){
        this.$refs.myInput4.focus()
      }
    },
    onInputChange4() {
      if(this.code4.length == 1){
        this.$refs.myInput5.focus()
      }
    },
    onInputChange5() {
      if(this.code5.length == 1){
        this.$refs.myInput6.focus()
      }
    },
    sureSubmit() {
      this.authenticationRes = 1
      this.ifNameCheck = false
      this.ifAuthentication = true
      this.axios.post('/api/user/certification', {
        name: this.authName,
        certificationPhone: this.phoneNum.replace(/\s+/g, ''),
        certificationNo: this.authIdcard
      })
      .then(response=> {
        if(response.data.code == 0){
          this.authenticationRes = 3
        }else{
          this.authenticationRes = 2
          this.failMsg = response.data.userTip
        }
        this.authName = ''
        this.authIdcard = ''
        this.phoneNum = ''
      })
      .catch(error=> {
          console.log(error)
      })

    },
    getInfo() {
      this.ifAuthentication = false
      this.queryUserInfo()
    },
    getCode() {
      this.axios.post('/api/verifyCode', {
        phone: this.phoneNum.replace(/\s+/g, ''),
      })
      .then(response=> {
        if(response.data.code == 0){
          this.$toast('发送成功')
          this.$refs.myInput1.focus()
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    checkVerifyCode() {
      this.axios.post('/api/user/checkCertificationVerifyCode', {
        certificationPhone: this.phoneNum.replace(/\s+/g, ''),
        verifyCode:  this.code1+''+this.code2+''+this.code3+''+this.code4+''+this.code5+''+this.code6
      })
      .then(response=> {
        if(response.data.code == 0){
          this.ifRealName = false
          this.ifNameCheck = true
          this.code1 = ''
          this.code2 = ''
          this.code3 = ''
          this.code4 = ''
          this.code5 = ''
          this.code6 = ''
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    onCancel() {
      this.queryUserInfo()
    },
    chargeMoney() {
      this.$router.push("/recharge")
    },
    chargeRecode() {
      this.$router.push("/chargeRecord")
    },
    goAccount() {
      this.$router.push("/accountReport")
    },
    goShare() {
      this.$router.push("/proxyShare")
    }
  },
  mounted() {
    if (localStorage.getItem('token') ==null){
        this.$router.push("/index")
    }else{
      this.queryUserInfo()
      this.queryHandUpInfo()
      this.queryMoney()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 38px 16px;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .tab-item{
    color: rgba(103, 110, 125, 1);
    margin-top:2px;
    font-size: 10px;
    font-weight: bold;
  }
  .active-tap{
      font-weight: bold;
      font-size:20px;
  }
  .normal-tap{
      color:rgba(103, 110, 125, 1);
      font-size:16px;
  }
</style>
