<template>
  <div class="index">
    <div style="text-align: center">
      <a-icon @click="goback" style="float: left;color:rgba(44, 54, 74, 1);font-size:20px;" type="close" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">挂机名片设置</span>
      <a-icon @click="changeHangUpCard" style="float: right;color:rgba(120, 86, 255, 1);font-size:22px;" type="check" />
    </div>
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:24px;">姓名/昵称</div>
    <a-input v-model.trim="cardName" style="border:none;background:#fff;height:52px;margin-top:8px;font-size:17px;font-weight: bold;" placeholder="请输入值" />
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:20px;">手机号</div>
    <a-input v-model.trim="cardPhone" type="number" style="border:none;background:#fff;height:52px;margin-top:8px;font-size:17px;" placeholder="请输入值" />
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:20px;">微信号</div>
    <a-input v-model.trim="cardWechat" style="border:none;background:#fff;height:52px;margin-top:8px;font-size:17px;" placeholder="请输入值" />
    <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin-top:20px;">简介</div>
    <a-textarea v-model.trim="cardDescription" style="border:none;background:#fff;height:220px;margin-top:8px;font-size:17px;" placeholder="请输入值"  />
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        cardName: '',
        cardPhone: '',
        cardWechat: '',
        cardDescription: '',
      }
  },
  methods: {
    goback() {
      this.$router.go(-1); 
    },
    changeHangUpCard() {
      this.axios.post('/api/user/updateHangUpCard', {
        cardName: this.cardName,
        cardPhone: this.cardPhone.replace(/\s+/g, ''),
        cardWechat: this.cardWechat,
        cardDescription: this.cardDescription,
      })
      .then(response=> {
        if(response.data.code == 0){
          this.$toast('修改成功')
          this.$router.go(-1); 
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    }
  },
  mounted() {
    this.cardName = this.$route.query.cardName
    this.cardPhone = this.$route.query.cardPhone
    this.cardWechat = this.$route.query.cardWechat
    this.cardDescription = this.$route.query.cardDescription
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 16px 0;
    background: rgba(249, 249, 249, 1);
  }
</style>
