<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      locale: zhCN,
    }
  },
  methods: {
    // updateData(newData) {
    //   this.$refs.childComponent.ifshowSearch = newData
    // }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  height: 100%;
  width: 100%;
  background: rgba(250, 250, 250, 1);
}
::-webkit-scrollbar {
  width: 0; /* 设置滚动条的宽度 */
  height: 0;
  background-color: #f5f5f5; /* 设置滚动条的背景色 */
}
/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
    background-color: #DCDBDB; /* 设置滑块的颜色 */
    border-radius: 10px; /* 设置滑块的圆角 */
}

/* 鼠标悬停在滑块上时的样式 */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* 设置鼠标悬停时的颜色 */
}
</style>
