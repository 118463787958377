<template>
    <div class="login-task">
        <div style="height:78px;background:#fff;position:fixed;left:0;top:0;width:100%;border-bottom:1px solid rgba(229, 231, 235, 1);padding:16px 24px;z-index:100">
            <div style="display:inline-block;width:46px;height:46px;border-radius:8px;background:rgba(0, 0, 0, 1);text-align:center;line-height:46px;">
                <img style="width:35px;" src="../assets/image/avatar.png" alt="">
            </div>
            <span style="color:rgba(44, 54, 74, 1);font-size:16px;font-weight:bold;margin-left:9px;">{{headInfo.projectName}}</span>
            <div style="float:right;margin-top:10px;">
                <img style="width:27px;" src="../assets/image/user2.png" alt="">
                <span style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight:bold;float:right;margin:4px 0 0 6px;">{{headInfo.phone}}</span>
            </div>
        </div>
        <div style="height:206px;padding:20px;border:1px solid rgba(229, 231, 235, 1);background:#fff;display:flex;box-shadow: rgba(229, 231, 235, 1) solid 1px;border-radius:8px;">
            <a-upload-dragger
                key={Math.random()}
                name="file"
                :multiple="false"
                :customRequest="customRequest"
                @change="onUpload"
                ref="pathClear"
                style="flex:1"
            >
                <p class="">
                    <img style="width:56px;" src="../assets/image/up-load.svg" alt="">
                </p>
                <p style="color:rgba(80, 80, 80, 1);font-size:14px;margin:13px 0 10px 0">
                    点击或将文件拖拽到这里上传，单次最大支持10M文件上传
                </p>
                <p style="color:rgba(153, 153, 153, 1);font-size:12px;">
                    支持扩展名：.csv .txt .xls .xlsx
                </p>
            </a-upload-dragger>
            <span style="display:inline-block;width:88px;height:36px;text-align:center;line-height:36px;background:rgba(121, 72, 234, 1);color:#fff;font-size:14px;border-radius:6px;float:left;margin:128px 0 0 20px;cursor:pointer;" @click="sureNormal">确认上传</span>
        </div>
        <div class="table-data">
            <div style="height:48px;border-bottom:1px solid rgba(229, 231, 235, 1);padding:14px 20px;">
                <span style="color:rgba(18, 18, 18, 1);font-size:14px;font-weight:bold;">待呼号码列表</span>
                <div @click="queryAgain" style="float:right;color:rgba(121, 72, 234, 1);font-size:16px;cursor: pointer;">
                    <a-icon type="redo" />
                    <span style="margin-left:4px;">刷新</span>
                </div>
            </div>
            <div style="padding:20px 24px;">
                <a-table
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :data-source="tableData"
                    :pagination='false'
                    :scroll="{ x: 'max-content' }"
                    :loading='loading'
                    :pageSizeOptions="['10', '50', '100', '200']"
                >
                    <a-table-column key="phone" title="待呼号码" data-index="phone" />
                    <a-table-column key="importType" title="导入方式" data-index="importType" />
                    <a-table-column key="createTime" title="导入时间" data-index="createTime" />
                    <a-table-column key="downloadUrl" title="操作" data-index="downloadUrl">
                        <template slot-scope="text, record">
                            <span style="color:rgba(121, 72, 234, 1);cursor:pointer;" @click="deleteCall([record.id])">删除</span>
                        </template>
                    </a-table-column>
                </a-table>
                <div class="pagination-table">
                    <span @click="deleteLot" v-if="selectedRowKeys.length!=0" style="display:inline-block;cursor:pointer;width:72px;height:32px;line-height:32px;text-align:center;border-radius:4px;color:#fff;background:rgba(246, 76, 79, 1);border-radius:4px;font-size:14px;float:left">批量删除</span>
                    <a-pagination
                        style="text-align:right;margin-top:16px;"
                        show-size-changer
                        :total="total"
                        :show-total="total => `共 ${total} 条     `"
                        v-model="currentPage"
                        @change="pageChange"
                        @showSizeChange="onShowSizeChange"
                        :pageSizeOptions="['10', '50', '100', '500', '1000']"
                    />
                </div>
            </div>
        </div>          
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    data() {
        return {
            newFormData: '',
            selectedRowKeys: [],
            columns: [],
            tableData: [],
            loading: false,
            currentPage:1,
            pageSize: 10,
            total: 0,
            token: '',
            headInfo: '',
        }
    },
    methods: {
        customRequest(data) { // 上传提交
            this.newFormData = ''
            const formData = new FormData() 
            formData.append('file', data.file)
            this.newFormData = formData
        },
        onUpload() {
            this.$refs.pathClear.value = ''
        },
        sureNormal() {
            this.axios({
                method: 'post',
                url: '/api/callData/import',
                params:{
                    waitingCallToken: this.token
                },
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                data: this.newFormData,
            
            }).then((response) => {
                if(response.data.code == 0){
                    this.newFormData = ''
                    this.$message.success('上传成功');
                    this.queryCallData()
                    // location.reload();
                }else{
                    this.$message.error(response.data.userTip);
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
            console.log(this.selectedRowKeys)
        },
        viewDetail1(id) {
            console.log(id)
        },
        onShowSizeChange(current, pageSize) {
            this.currentPage = current
            this.pageSize = pageSize
            this.queryCallData()
        },
        pageChange(current, pageSize) {
            this.currentPage = current
            this.pageSize = pageSize
            this.queryCallData()
        },
        queryHeadInfo() {
            this.axios( 
                {
                    method:'get',
                    url:'/api/callData/baseInfo', 
                    params: {
                        waitingCallToken: this.token
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    console.log(response.data.data)
                    this.headInfo = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        queryCallData() {
            this.axios( 
                {
                    method:'get',
                    url:'/api/callData/waitingCallList', 
                    params: {
                        waitingCallToken: this.token,
                        current: this.currentPage,
                        size: this.pageSize
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    console.log(response.data.data)
                    this.tableData = response.data.data.records
                    console.log(this.tableData)
                    this.total = response.data.data.total 
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        deleteCall(id) {
            let that = this
            this.$confirm({
                    title: '确定要删除这条数据吗？',
                    content: '',
                onOk() {
                    that.sureDelete(id)
                },
                onCancel() {

                },
            });
        },
        sureDelete(id) {
            this.axios.post('/api/callData/deleteWaitingCall', {
                waitingCallToken: this.token,
                idList: id
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.currentPage = 1
                    this.pageSize = 10
                    this.queryCallData()
                    this.$message.success('删除成功')
                    this.selectedRowKeys = []
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        deleteLot() {
            let arr = []
            for(let i = 0; i < this.selectedRowKeys.length; i++){
                arr.push(this.tableData[this.selectedRowKeys[i]]['id'])
            }
            console.log(arr)
            this.deleteCall(arr)
        },
        queryAgain() {
            this.selectedRowKeys = []
            this.currentPage = 1
            this.pageSize = 10
            this.queryCallData()
        }
    },
    mounted() {
        this.token = this.$route.query.token
        this.queryHeadInfo()
        this.queryCallData()
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .login-task{
        width: 100%;
        height: 100%;
        padding: 102px 24px 24px;
        background:rgba(250, 250, 250, 1);
    }
    .table-data{
        background: #fff;
        border: 1px solid rgba(229, 231, 235, 1);
        margin-top:20px;
        border-radius: 8px;
    }
  </style>
  