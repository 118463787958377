<template>
  <div class="index">
    <div style="position: fixed;background:rgba(249, 249, 249, 1);width:100%;left:0;top:0;padding: 27px 16px 12px;z-index: 10;">
      <a-icon @click="goback" style="font-size:18px;color:rgba(4, 4, 4, 1);margin-right: 6px;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;">挂机短信</span>
    </div>
    <div style="height: 64px;background:#fff;padding:12px 17px;border-radius: 12px;display: flex;margin:15px 16px 0">
      <div style="flex:1">
        <div style="color:rgba(44, 54, 74, 1);font-size:13px;">是否开启Bridge挂机短信服务</div>
        <div style="color:rgba(153, 155, 157, 1);font-size:10px;margin-top:4px;">针对有价值线索发送数字名片短信</div>
      </div>
      <van-switch @change="changeSwitch" style="margin-top:5px;" v-model="hangUpSms" />
    </div>
    <div style="padding:12px 16px 24px;background:rgba(249, 249, 249, 1);overflow-y: auto;flex: 1" @scroll="handleScroll">
      <div style="background:#fff;border-radius: 12px;padding: 16px;">
        <div style="color:rgba(103, 110, 125, 1);font-size:12px;">点击短信客户列表</div>
        <div style="width:100%;height: 100%;position: relative;height: 60px;margin-top:8px;">
          <a-date-picker :locale="locale" @change="onChange" style="border: none;opacity: 0;z-index: 10;position: absolute;left:0;top:0;" />
          <div v-if="month!=''" style="position: absolute;top:0px;left:0px;font-size:20px;z-index: 1;">
            <span style="color:rgba(44, 54, 74, 1);font-size:40px;font-weight: bold;">{{month}}</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 14px;margin:0 8px">/</span>
            <span style="color:rgba(44, 54, 74, 1);font-size: 16px;font-weight: bold;margin-right:6px;">{{ day }}</span>
            <a-icon type="caret-down" />
          </div>
          <div v-if="month==''" style="position: absolute;top:0px;left:0px;font-size:16px;z-index: 1;color:#ccc">请选择日期</div>
        </div>
        <div style="margin-top:8px;">
          <van-popover
              v-model="showPopover"
              trigger="click"
              :actions="actions"
              @select="onSelect"
              style="display: inline-block;"
              
          >
              <template #reference>
                  <span style="color:rgba(103, 110, 125, 1);font-size:13px">{{starText}}</span>
                  <a-icon style="color:rgba(103, 110, 125, 1);font-size:14px;margin-left:4px;" type="caret-down" />
              </template>
          </van-popover>
      </div>
        <div v-if="smsList.length!=0">
          <div @click="viewDel(item.id)" style="height: 58px;display: flex;margin-top:16px;" v-for="(item,index) in smsList" :key="index">
            <div style="display: inline-block;position: relative">
              <!-- <span v-if="item.checkStatus==1" style="display: inline-block;width: 10px;height: 10px;border-radius: 5px;background:rgba(246, 76, 79, 1);position: absolute;right: 0;top:0;"></span> -->
              <img src="../assets/image/user.png" style="width: 32px;height: 32px;border-radius: 16px;" alt="">
            </div>
            <div style="flex: 1;margin:0 8px;">
              <div style="color:rgba(44, 54, 74, 1);font-size: 15px;font-weight: bold;">{{item.phone}}</div>
              <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.name}}</div>
              <div style="color:rgba(103, 110, 125, 1);font-size:12px;">{{item.duration}}</div>
            </div>
            <div>
              <div style="height:28px;">
                <img v-if="item.userLook!=0" style="width:16px;float: right;" src="../assets/image/isread.png" alt="">
              </div>
              <div>
                <span style="color:rgba(179, 184, 193, 1);font-size:12px;margin-right:9px;">{{item.callTime}}</span>
                <img style="width: 10px;opacity: 0.5;" src="../assets/image/sanjiao.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div v-if="smsList.length==0" style="text-align: center;">
          <img style="width:40%;margin-top:50px;" src="../assets/image/no-data.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    
  },
  data() {
      return {
        locale,
        zhCN,
        year: '',
        month: '',
        day: '',
        hangUpSms: '',
        smsList: [],
        current: 1,
        totalNum: 0,
        showPopover: false,
        // 通过 actions 属性来定义菜单选项
        actions: [
            { 
                text: '全部状态',
                id: null
            }, 
            { 
                text: '客户已读',
                id: 1 
            }, 
            { 
                text: '客户未读',
                id: 2 
            }, 
        ],
        starText: '全部状态',
        starId: null
      }
  },
  methods: {
    moment,
    goback() {
      this.$router.go(-1); 
    },
    onChange(date, dateString) {
      let str = dateString.split("-")
      console.log(str)
      this.year = str[0]
      this.month = str[1]
      this.day = str[2]
      this.querySmsList()
    },
    changeSwitch() {
      this.axios.post('/api/user/updateHangUpSms', {//1是关，2是开
        hangUpSms: this.hangUpSms?2:1
      })
      .then(response=> {
        if(response.data.code == 0){
          if(this.hangUpSms){
            this.$toast('开启成功')
          }else{
            this.$toast('关闭成功')
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
          console.log(error)
      })
    },
    querySmsList() {
      this.axios( 
        {
          method:'get',
          url:'/api/callData/smsList', 
          params: {
            current: this.current,
            size: 10,
            callDate: this.year+'-'+this.month+'-'+this.day,
            type: this.starId
          }
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.totalNum = response.data.data.total
          if(this.current == 1){
            this.smsList = response.data.data.records
          }else{
            this.smsList = this.smsList.concat(response.data.data.records)
          }
        }else{
          this.$toast(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    },
    handleScroll(event) {
      // this.ifScreen = false
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // console.log('scrollTop--'+scrollTop)
      // console.log('scrollHeight--'+ scrollHeight)
      // console.log('clientHeight--'+clientHeight)
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度 - 一定的阈值
      if (scrollTop + clientHeight >= scrollHeight) { // 50是一个阈值，可以根据需要调整
          if(this.totalNum != this.smsList.length){
              this.current ++
              this.querySmsList()
          } 
      }
    },
    viewDel(id) {
      this.$router.push({
          path: '/callDel',
          query:{
              id: id,
          }
      });
    },
    onSelect(action) {
      this.current = 1
      this.starText = action.text
      this.starId = action.id
      this.querySmsList()
    },
  },
  mounted() {
    const today = new Date(); // 创建一个新的 Date 对象
    this.year = today.getFullYear(); // 获取年份
    this.month = (today.getMonth() + 1)<10?'0'+(today.getMonth() + 1):(today.getMonth() + 1); // 获取月份（0-11），所以要加1
    this.day = today.getDate()<10?'0'+today.getDate():today.getDate(); // 获取日期（1-31）
    this.hangUpSms = this.$route.query.hangUpSms==1?false:true
    this.querySmsList()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 44px 0 0;
    background: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
  }
</style>
