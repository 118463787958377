import { render, staticRenderFns } from "./endMsg.vue?vue&type=template&id=4ffd7434&scoped=true"
import script from "./endMsg.vue?vue&type=script&lang=js"
export * from "./endMsg.vue?vue&type=script&lang=js"
import style0 from "./endMsg.vue?vue&type=style&index=0&id=4ffd7434&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffd7434",
  null
  
)

export default component.exports