<template>
    <div class="index">
      <a-button type="primary" @click="queryCode">
        登录
      </a-button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    computed: {
      
    },
    data() {
        return {
          
        }
    },
    methods: {
      queryCode() {
        const appId = 'wx0c399502b45aa502'; // 替换为你的 App ID
        const redirectUri = encodeURIComponent('https://www.anybridges.com/index'); // 替换为你的回调链接
        const scope = 'snsapi_userinfo';
        const state = 'random_string'; // 随机字符串
        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
        window.location.href = authUrl; // 跳转到微信授权页面
      }
    },
    mounted() {
      
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
   
  </style>
  