<template>
    <div class="index">
      <div style="position: fixed;height: 50px;background: #fff;color: rgba(44, 54, 74, 1);font-size:20px;line-height: 65px;top:0;left:0;width:100%;text-align: left;padding-left: 16px;font-weight: bold;">
        Bridge·AI群呼
      </div>
      <div>
      </div>
      <div style="flex: 1;overflow: hidden;display: flex;">
        <div style="width: 62px;height: 100%;overflow-y: auto;background:rgba(243, 245, 248, 1);border-radius: 0px 10px 0px 0px;padding: 12px 0 12px 0;text-align: center;">
          <div v-if="isLogin" :class="activeTap==-2?'common-tap-active':'common-tap'" @click="chooseTap(-2)">
            <span class="left-bod" v-if="activeTap==-2"></span>
            <div>
              <a-icon style="color:rgba(124, 84, 252, 1);font-size:14px;" type="pie-chart" />
            </div>
            <span style="color:rgba(120, 86, 255, 1);font-size:10px;font-weight: bold;">最近</span>
          </div>
          <div v-if="isLogin" :class="activeTap==-1?'common-tap-active':'common-tap'" style="margin-bottom:0px;" @click="chooseTap(-1)">
            <span class="left-bod" v-if="activeTap==-1"></span>
            <div>
              <a-icon style="color:rgba(124, 84, 252, 1);font-size:14px;" type="radius-setting" />
            </div>
            <span style="color:rgba(120, 86, 255, 1);font-size:10px;font-weight: bold;">定制</span>
          </div>
          <div v-if="isLogin" style="height: 1px;margin:8px 0 7px 0;text-align: center;padding: 0 7px">
            <div style="height: 1px;background:rgba(229, 231, 235, 1)"></div>
          </div>
          <div v-for="(item,index) in categoryList" :key="index">
            <div :class="activeTap==item.categoryId?'common-tap-active':'common-tap'" @click="chooseTap(item.categoryId)">
              <span class="left-bod" v-if="activeTap==item.categoryId"></span>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div style="flex: 1;padding: 0 16px;overflow-y: auto;">
          <img v-if="ifShowBanner" style="width: 100%;border-radius: 10px;margin-bottom: 20px;" :src="bannerBg" alt="">
          <div v-if="activeTap!=-1&&activeTap!=-2">
            <div v-for="(item,index) in listByCategoryIdData" :key="index" style="margin-bottom: 20px;background:rgba(243, 245, 248, 1);padding: 10px;border-radius:10px;" @click="viewDetail(item.id)">
              <div style="display: flex;">
                <div style="width: 60px;height: 60px;border-radius: 8px;overflow: hidden;position: relative;">
                  <img style="width: 100%;height: 100%;" :src="item.avatar" alt="">
                </div>
                <div style="flex: 1;margin-left: 12px;display: flex;">
                  <div style="flex: 1;">
                    <div style="color:rgba(0, 0, 0, 1);font-size:17px;padding-top:10px">
                      <span>{{ item.name }}</span>
                    </div>
                    <div style="color:rgba(246, 76, 79, 1);">
                      <img style="width: 9px;" src="../assets/image/fire.png" alt="">
                      <span style="font-size:10px;font-weight: bold;margin-left: 2px;">{{item.heat}}</span>
                    </div>
                  </div>
                  <div style="display: inline-block;width: 40px;line-height: 60px;">
                    <!-- <a-icon style="color:rgba(100, 108, 124, 1);font-size:20px;color:rgba(104, 108, 124, 1)" type="caret-right" /> -->
                    <img src="../assets/image/sanjiao.png" style="width:12px;height:16px;float:right;margin:22px 2px 0 0;opacity: 0.3;" alt="">
                  </div>
                </div>
              </div>
              <div style="margin-top:9px;border-radius: 8px;background: #fff;border-radius: 8px;height: 54px;padding: 8px 12px;color:rgba(103, 110, 125, 1);font-size:12px;display: flex;overflow-y:hidden">
                <img style="width:18px;height:18px;margin-right:9px;opacity: 0.3;" src="../assets/image/yinhao.png" alt="">
                <div style="flex: 1;height:38px;overflow:hidden">
                  {{item.prologue}}
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTap==-1&&myCustomizedData.length!=0">
            <div v-for="(item,index) in myCustomizedData" :key="index" style="margin-bottom: 20px;background:rgba(243, 245, 248, 1);padding: 10px;border-radius:10px;" @click="viewDetail(item.id)">
              <div style="display: flex;">
                <div style="width: 60px;height: 60px;border-radius: 8px;overflow: hidden;position: relative;">
                  <img style="width: 100%;height: 100%;" :src="item.avatar" alt="">
                </div>
                <div style="flex: 1;margin-left: 12px;display: flex;">
                  <div style="flex: 1;">
                    <div style="color:rgba(0, 0, 0, 1);font-size:17px;padding-top:10px">
                      <span>{{ item.name }}</span>
                    </div>
                    <div style="color:rgba(246, 76, 79, 1);">
                      <img style="width: 9px;" src="../assets/image/fire.png" alt="">
                      <span style="font-size:10px;font-weight: bold;margin-left: 2px;">{{item.heat}}</span>
                    </div>
                  </div>
                  <div style="display: inline-block;width: 40px;line-height: 60px;">
                    <a-icon style="color:rgba(100, 108, 124, 1);font-size:20px;color:rgba(104, 108, 124, 1)" type="caret-right" />
                  </div>
                </div>
              </div>
              <div style="margin-top:9px;border-radius: 8px;background: #fff;border-radius: 8px;height: 54px;padding: 8px 12px;color:rgba(103, 110, 125, 1);font-size:12px;display: flex;overflow-y:hidden">
                <img style="width:18px;height:18px;margin-right:9px;opacity: 0.3;" src="../assets/image/yinhao.png" alt="">
                <div style="flex: 1;height:38px;overflow:hidden">
                  {{item.prologue}}
                </div>
              </div>
              <div style="margin-top:7px;">
                <div style="display:inline-block;">
                  <span style="color:rgba(103, 110, 125, 1);font-size:11px;">场景版权@</span>
                  <img src="../assets/image/head3.png" style="width:16px;border-radius:8px;margin:0 1px;" alt="">
                  <span style="color:rgba(103, 110, 125, 1);font-size:11px;">{{item.ownerPhone}}</span>
                </div>
                <span @click.stop="viewPwd(item.projectKey)" style="color:rgba(120, 86, 255, 1);font-size:11px;float:right;margin-top:4px;">查看秘钥</span>
              </div>
            </div>
          </div>
          <div v-if="activeTap==-2&&recentlyOpenData.length!=0">
            <div v-for="(item,index) in recentlyOpenData" :key="index" style="margin-bottom: 20px;background:rgba(243, 245, 248, 1);padding: 10px;border-radius:10px;" @click="viewDetail(item.id)">
              <div style="display: flex;">
                <div style="width: 60px;height: 60px;border-radius: 8px;overflow: hidden;position: relative;">
                  <img style="width: 100%;height: 100%;" :src="item.avatar" alt="">
                </div>
                <div style="flex: 1;margin-left: 12px;display: flex;">
                  <div style="flex: 1;">
                    <div style="color:rgba(0, 0, 0, 1);font-size:17px;padding-top:10px">
                      <span>{{ item.name }}</span>
                    </div>
                    <div style="color:rgba(246, 76, 79, 1);">
                      <img style="width: 9px;" src="../assets/image/fire.png" alt="">
                      <span style="font-size:10px;font-weight: bold;margin-left: 2px;">{{item.heat}}</span>
                    </div>
                  </div>
                  <div style="display: inline-block;width: 40px;line-height: 60px;">
                    <a-icon style="color:rgba(100, 108, 124, 1);font-size:20px;color:rgba(104, 108, 124, 1)" type="caret-right" />
                  </div>
                </div>
              </div>
              <div style="margin-top:9px;border-radius: 8px;background: #fff;border-radius: 8px;height: 54px;padding: 8px 12px;color:rgba(103, 110, 125, 1);font-size:12px;display: flex;overflow-y:hidden">
                <img style="width:18px;height:18px;margin-right:9px;opacity: 0.3;" src="../assets/image/yinhao.png" alt="">
                <div style="flex: 1;height:38px;overflow:hidden">
                  {{item.prologue}}
                </div>
              </div>
            </div>
          </div>
          <div v-if="(activeTap==-1&&myCustomizedData.length==0)||(activeTap==-2&&recentlyOpenData.length==0)" style="width: 100%;height: 98px;border-radius: 10px;margin-top: 20px;background: #FDEEF6;text-align: center;">
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;padding-top: 20px;text-align: center;">私有话术定制99元起</div>
            <span @click="goWx" style="display: inline-block;width: 100px;height: 32px;line-height: 32px;color:#fff;text-align: center;border-radius: 6px;background: rgba(120, 86, 255, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);margin-top: 8px;">定制咨询</span>
          </div>
        </div>
      </div>

      <div v-if="screenVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
        <div style="width:100%;height: 100%;position: relative;">
          <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
          <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
            <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;position: relative;">
              <span>绑定手机号码</span>
              <a-icon @click="closeScreen" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
            </div>
            <div style="text-align: center;margin:24px 0 13px">
              <img style="width: 71px;" src="../assets/image/head1.png" alt="">
            </div>
            <div style="text-align: center">
              <img src="../assets/image/ai.png" style="width: 131px;" alt="">
            </div>
            <div style="height: 88px;background: #fff;border-radius: 8px;margin-top: 28px;position: relative;padding: 22px 20px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);">
                <a-input class="custom-input" v-model.trim="phoneNum" type="number" style="border: none;background: none;font-size:18px" placeholder="请输入手机号" />
                <span @click="getCode" style="display: inline-block;width: 263px;height: 48px;line-height: 48px;border-radius: 12px;text-align: center;color:#fff;font-size:16px;background: rgba(120, 86, 255, 1);margin-top:5px;">获取验证码</span>
            </div>
            <!-- <div style="color:rgba(103, 110, 125, 1);font-size: 12px;margin-top:30px;text-align: center;">若手机号未注册，登录后会自动注册</div> -->
          </div>
        </div>
      </div>
      <div v-if="pcVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
        <div style="width:100%;height: 100%;position: relative;">
          <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
          <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
            <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 24px;position: relative;">
              <span>绑定手机号码</span>
              <a-icon @click="closePc" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
            </div>
            <div style="color:rgba(103, 110, 125, 1);font-size:13px;">请输入验证码</div>
            <div style="margin-top: 12px;">
              <a-row style="text-align: center;">
                <a-col :span="4">
                  <a-input ref="myInput1"  @input="onInputChange1" v-model="code1" type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="4">
                  <a-input ref="myInput2"  @input="onInputChange2" v-model="code2" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="4">
                  <a-input ref="myInput3"  @input="onInputChange3" v-model="code3" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="4">
                  <a-input ref="myInput4"  @input="onInputChange4" v-model="code4" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="4">
                  <a-input ref="myInput5"  @input="onInputChange5" v-model="code5" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="4">
                  <a-input ref="myInput6" v-model="code6" oninput="if(value.length>1)value=value.slice(0,1)" type="number" :maxLength=1 style="display: inline-block;width:48px;height:48px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
              </a-row>
            </div>
            <div @click="loginIn" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:48px">确认</div>
          </div>
        </div>
      </div>

      <div style="position: fixed;bottom:0;left:0;width: 100%;height: 56px;background: rgba(249, 249, 249, 1);padding-top: 6px;">
        <a-row style="text-align: center;">
          <a-col :span="8" >
            <div style="margin: 4px 0 0px 0;">
              <!-- <a-icon type="phone" style="font-size:18px;color:rgba(120, 86, 255, 1);transform: rotate(90deg);" /> -->
              <img style="width:22px;" src="../assets/image/1-1.png" alt="">
            </div>
            <div class="tab-item" style="color:rgba(120, 86, 255, 1)">群呼</div>
          </a-col>
          <a-col :span="8">
            <div style="margin: 4px 0 0px 0;">
              <!-- <a-icon @click="goContact" type="user" style="font-size:18px" /> -->
              <img @click="goContact" style="width:22px;" src="../assets/image/2-0.png" alt="">
            </div>
            <div class="tab-item">
              <span @click="goContact">客户</span>
            </div>
          </a-col>
          <a-col :span="8">
            <div style="margin: 4px 0 0px 0;">
              <!-- <a-icon type="user" style="font-size:18px" /> -->
              <img @click="goMine" style="width:22px;" src="../assets/image/3-0.png" alt="">
            </div>
            <div @click="goMine" class="tab-item">我的</div>
          </a-col>
        </a-row>
      </div>
      <div v-if="ifshowStatus" style="height: 40px;line-height: 40px;text-align: center;padding: 0 16px;position: fixed;width:100%;left:0;bottom:95px;">
        <div style="border-radius: 8px;background:rgba(44, 54, 74, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);height: 40px;color:#fff;font-size:13px">     
          <img style="width:20px;margin-right:4px;" src="../assets/image/white-check.svg" alt="">
          <span>秘钥复制成功</span>
        </div>
      </div>
      <van-action-sheet v-model="ifPwd" :closeable="false" title="">
        <div style="padding: 16px 20px 24px;position:relative;">
          <div style="color:rgba(44, 54, 74, 1);font-size:16px;font-weight:bold;text-align:center">
            查看秘钥
          </div>
          <a-icon @click="hidePwd" style="color:rgba(103, 110, 125, 1);font-size:16px;position:absolute;right:18px;top:16px;" type="close" />
          <div style="padding:10px 12px;background:rgba(179, 184, 193, 0.16);border-radius:8px;margin:20px 0">{{pwdKey}}</div>
          <div @click="copyPwd" style="color:#fff;font-size:15px;background:rgba(120, 86, 255, 1);border-radius:8px;text-align:center;height:40px;line-height:40px;">复制秘钥</div>
        </div>
      </van-action-sheet>
      <van-action-sheet v-model="ifNewPwd" :closeable="false" title="">
        <div style="padding: 16px 20px 24px;position:relative;">
          <div style="color:rgba(44, 54, 74, 1);font-size:16px;font-weight:bold;text-align:center">
            添加定制场景
          </div>
          <a-icon @click="hideNew" style="color:rgba(103, 110, 125, 1);font-size:16px;position:absolute;right:18px;top:16px;" type="close" />
          <div style="color:rgba(103, 110, 125, 1);font-size:13px;margin:20px 0 9px">
            输入场景秘钥
          </div>
          <a-textarea
            v-model="pwdVal"
            placeholder="请输入key"
            style="height:52px;padding:15px 16px;border:none;background:rgba(179, 184, 193, 0.16);border-radius:8px;margin-bottom:24px;"
          />
          <div @click="sureSubmit" style="color:#fff;font-size:15px;background:rgba(120, 86, 255, 1);border-radius:8px;text-align:center;height:40px;line-height:40px;">确认提交</div>
        </div>
      </van-action-sheet>
      <div v-if="activeTap==-1" @click="addPwd" style="width: 52px;height:52px;border-radius: 26px;background:rgba(120, 86, 255, 1);position: fixed;right: 24px;bottom:80px;box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);text-align: center;line-height: 56px;">
          <a-icon style="color:rgba(255, 255, 255, 1);font-size:22px;" type="plus" />
      </div>
      <a-input id="copyHtml1" readonly="readonly" style="position: fixed;left: 10000000px;top:10000000px;"/>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    computed: {
      
    },
    data() {
        return {
          leftList: [
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
            {
              name: '最近打开',
              id: 1
            },
          ],
          robotList: [
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '空闲'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
            {
              name: '机器人名字',
              content: '很简短的概括性的机器人简介很简短的概括性的机器人简介',
              status: '呼叫中'
            },
          ],
          activeTap: null,
          bannerBg: '',
          categoryList: [],
          myCustomizedData: [],
          recentlyOpenData: [],
          listByCategoryIdData: [],
          isLogin: false,
          ifShowBanner: false,
          screenVisible: false,
          pcVisible: false,
          phoneNum: '',
          code1: '',
          code2: '',
          code3: '',
          code4: '',
          code5: '',
          code6: '',
          ifPwd: false,
          pwdKey: '',
          ifshowStatus: false,
          ifNewPwd: false,
          pwdVal: '',
          copyHtml2: '',
          code: '',
          userId: '',
          shareUser: ''
        }
    },
    methods: {
      viewDetail(id) {
        if(this.shareUser!=''&&this.shareUser!=undefined&&this.shareUser!="undefined"){
          this.$router.push({
            path: '/detail',
            query:{
              id: id,
              userid: this.shareUser
            }
          });
        }else{
          this.$router.push({
            path: '/detail',
            query:{
              id: id,
            }
          });
        }
      },
      chooseTap(index) {
        console.log(index)
        this.activeTap = index
        if(index==-1){
          this.queryMyCustomized()
        }else if(index == -2){
          this.queryRecentlyOpen()
        }else{
          this.queryListByCategoryId(index)
        }
      },
      goContact() {
        if (localStorage.getItem('token') ==null){
          // this.screenVisible = true
          this.queryCode()
        }else{
          this.$router.push("/contacts")
        }
      },
      queryBanner() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/banner', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            if(response.data.data.length > 0){
              this.bannerBg = response.data.data[0]
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryRobotList() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/categoryList', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            if(response.data.data.length > 0){
              console.log(response.data.data)
              this.categoryList = response.data.data
              if (localStorage.getItem('token') ==null) {
                if(this.categoryList.length > 0){
                  this.activeTap = this.categoryList[0]['categoryId']
                  this.queryListByCategoryId(this.activeTap)
                }
              }
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryMyCustomized() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/myCustomized', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.myCustomizedData = response.data.data
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryRecentlyOpen() {
        this.axios( 
          {
            method:'get',
            url:'/api/project/recentlyOpen', 
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.recentlyOpenData = response.data.data
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryListByCategoryId(id) {
        this.axios( 
          {
            method:'get',
            url:'/api/project/listByCategoryId', 
            params: {
              categoryId: id
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.listByCategoryIdData = response.data.data
            console.log(this.listByCategoryIdData)
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      goWx() {
        window.location.href = 'https://work.weixin.qq.com/kfid/kfc009eca4d2527ca4e'; // 替换为你想访问的链接
      },
      goMine() {
        // this.$toast('内测阶段，暂未开放')
        if (localStorage.getItem('token') ==null){
          // this.screenVisible = true
          this.queryCode()
        }else{
          this.$router.push("/mine")
        }
      },

      closeScreen() {
        this.screenVisible = false
      },
      sureScreen() {
        this.screenVisible = false
      },
      register() {
        // this.screenVisible = true
        this.queryCode()
      },
      getCode() {
        this.axios.post('/api/verifyCode', {
          phone: this.phoneNum.replace(/\s+/g, ''),
        })
        .then(response=> {
          if(response.data.code == 0){
            // this.$message.success('发送成功')
            this.$toast('发送成功')
            this.screenVisible = false
            this.pcVisible = true
            this.$refs.myInput1.focus()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      closePc() {
        this.pcVisible = false
      },
      loginIn() {
        this.axios.post('/api/user/bindPhone', {
          phone: this.phoneNum.replace(/\s+/g, ''),
          verifyCode: this.code1+''+this.code2+''+this.code3+''+this.code4+''+this.code5+''+this.code6,
          userId: this.userId
        })
        .then(response=> {
          if(response.data.code == 0){
            // this.$message.success('登录成功')
            this.isLogin = true
            this.$toast('登录成功')
            this.sessionData('set','token',response.data.data.token);
            this.pcVisible = false
            // location.reload(); 
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      onInputChange1() {
        if(this.code1.length == 1){
          this.$refs.myInput2.focus()
        }
      },
      onInputChange2() {
        if(this.code2.length == 1){
          this.$refs.myInput3.focus()
        }
      },
      onInputChange3() {
        if(this.code3.length == 1){
          this.$refs.myInput4.focus()
        }
      },
      onInputChange4() {
        if(this.code4.length == 1){
          this.$refs.myInput5.focus()
        }
      },
      onInputChange5() {
        if(this.code5.length == 1){
          this.$refs.myInput6.focus()
        }
      },
      hideModel() {
        this.screenVisible = false
        this.pcVisible = false
      },
      viewPwd(pwd) {
        this.ifPwd = true
        this.pwdKey = pwd
      },
      hidePwd() {
        this.ifPwd = false
      },
      copyPwd() {
        let text = this.pwdKey
        let input = document.getElementById("copyHtml1");
        input.value = text; 
        input.select(); 
        document.execCommand("copy"); 
        this.ifshowStatus = true
        setTimeout(()=>{
          this.ifshowStatus = false
        },1500)
        this.ifPwd = false
      },
      sureSubmit() {
        this.ifNewPwd = false
        this.axios.post('/api/project/addCustomized', {
          projectKey: this.pwdVal,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.$toast('添加成功')
            this.queryMyCustomized()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      hideNew() {
        this.ifNewPwd = false
      },
      addPwd() {
        this.ifNewPwd = true
      },
      queryCode() {
        if(this.shareUser==undefined||this.shareUser=="undefined"){
          this.shareUser=''
        }
        const appId = 'wx0c399502b45aa502'; // 替换为你的 App ID
        const redirectUri = encodeURIComponent('https://www.anybridges.com/index?userid='+this.shareUser); // 替换为你的回调链接    https://www.anybridges.com/index
        const scope = 'snsapi_userinfo';
        const state = this.shareUser; // 随机字符串
        const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&userid=${state}#wechat_redirect`;
        window.location.href = authUrl; // 跳转到微信授权页面
      },
      loginWx() {
        if((this.shareUser==undefined)||(this.shareUser=="undefined")){
          this.shareUser=''
        }
        this.axios.post('/api/login/wechatLogin', {
          code: this.code==undefined?'':this.code,
          shareUser: this.shareUser
        })
        .then(response=> {
          if(response.data.code == 0){
            this.userId = response.data.data.userId
            if(response.data.data.flag == 1){
              this.$toast('登录成功')
              this.sessionData('set','token',response.data.data.token);
              this.sessionData('set','userid',response.data.data.userId);
              this.pcVisible = false
              // location.reload(); 
            }else{
              // this.$toast('未绑定手机号')
              this.screenVisible = true
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
    },
    mounted() {
      // this.sessionData('set','token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhdXRoMCIsImRhdGFKc29uIjoiXCIyJiZvazNPLXN4eXFrMm9qUXZrUUd2NTdBcnhqaUEwXCIifQ.cJHijeYYqoW8dq4U17M93CGWeY_S8Dqb8HnINAABqN8')
      this.code = this.$route.query.code
      this.shareUser = this.$route.query.userid
      if(this.shareUser == undefined){
        this.shareUser = ''
      }
      if(this.code!=''&&this.code!=undefined&&localStorage.getItem('token') ==null){
        this.loginWx()
      }
      if(window.innerWidth > 800){
        this.ifShowBanner = false
      }else{
        this.ifShowBanner = true
      }
      if (localStorage.getItem('token') !=null) {
        this.isLogin = true
        this.activeTap = -2
        this.queryRecentlyOpen()
      }
      this.queryBanner()
      this.queryRobotList()
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .index{
      width: 100%;
      height: 100%;
      position: relative;
      padding: 60px 0 56px 0;
      background: #fff;
      display: flex;
      flex-direction: column;
    }
    .tab-item{
      color: rgba(103, 110, 125, 1);
      font-size: 10px;
      margin-top:2px;
      font-weight: bold;
    }
    .common-tap{
      display: inline-block;
      width: 48px;
      height: 48px;
      padding: 6px 8px 0 8px;
      background: #fff;
      border-radius: 10px;
      font-size: 12px;
      margin-bottom: 10px;
      position: relative;
    }
    .common-tap-active{
      display: inline-block;
      width: 48px;
      height: 48px;
      padding: 6px 8px 0 8px;
      background: rgba(246, 237, 252, 1);
      border-radius: 10px;
      font-size: 12px;
      margin-bottom: 10px;
      position: relative;
      border: 2px solid rgba(120, 86, 255, 1);
      box-sizing: border-box;
      color:rgba(120, 86, 255, 1);
    }
    .left-bod{
      position: absolute;
      left: -8px;
      height: 38px;
      width: 3px;
      background: rgba(120, 86, 255, 1);
      display: inline-block;
      top:4px;
    }
    .custom-input:focus {
      box-shadow: 0 0 0 0 #fff !important; /* 自定义阴影 */
      outline: none !important; /* 去除默认的 outline */
    }
  </style>
  