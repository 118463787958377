<template>
    <div class="index">
      <div class="banner"></div>
      <!-- <div :class="ifShowTitle?'head-banner2':'head-banner1'">
        <div v-if="ifShowTitle" style="color:#fff;font-size:15px;text-align: center;margin-top:20px;">
          <span style="display: inline-block;width:28px;height:28px;border-radius: 14px;background:rgba(8, 7, 7, 1);margin-right:8px;">
            <img style="width:22px;border-radius: 11px;" :src="chatDelData.projectImage" alt="">
          </span>
          <span>{{ chatDelData.cardName }}</span>
        </div>
      </div> -->
      <div style="position: absolute;width: 100%;top:28px;padding: 0 16px;z-index:100000">
        <div style="display: flex;">
          <div style="flex:1">
            <div style="color:rgba(255, 255, 255, 1);font-size:18px;font-weight:bold">{{ chatDelData.cardName }}</div>
            <div style="margin-top:5px;">
              <span style="color:rgba(255, 255, 255, 1);font-size:14px;">{{ chatDelData.cardPhone }}</span>
              <img style="width:20px;margin-left:8px;" src="../assets/image/phone-quan.png" alt="">
            </div>
            <div style="display:flex;color:rgba(255, 255, 255, 1);font-size:12px;margin-top:16px">
              <span style="display:inline-block;width:48px;">微信号：</span>
              <span style="flex:1">{{ chatDelData.cardWechat }}</span>
            </div>
            <div style="display:flex;color:rgba(255, 255, 255, 1);font-size:12px;margin-top:3px">
              <span style="display:inline-block;width:48px;">简介：</span>
              <span style="flex:1">{{ chatDelData.cardDescription }}</span>
            </div>
          </div>
          <img style="width:52px;height: 52px;border-radius:26px;border:1px solid #fff;" :src="chatDelData.avatar" alt="">
        </div>
      </div>
      <div style="height: 76px;background:#fff;position: fixed;top: 204px;width: 100%;border-radius: 16px 16px 0 0;z-index: 100;padding: 12px 16px;">
        <div style="color:rgba(44, 54, 74, 1);font-weight: bold;font-size:16px;text-align: center;">{{chatDelData.phone}}</div>
        <audio controls style="width:100%">
          <source :src="voiceUrl" type="audio/ogg">
          您的浏览器不支持 audio 元素。
        </audio>
      </div>
      <div style="padding:80px 0 0 0;flex: 1;overflow-y: auto;">
        <div style="padding: 12px 0 0 16px;">
          <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align: center;">{{chatDelData.callTime}}</div>
          <div :class="chatDelData.labelList.length==0?'need-hide':''" style="height: 54px;padding-right: 16px;overflow-y: auto;margin-top:8px;margin-bottom: 4px;">
            <span v-for="(item,index) in chatDelData.labelList" :key="index" style="color:rgba(103, 110, 125, 1);background:rgba(243, 245, 248, 1);border:1px solid rgba(217, 217, 217, 1);border-radius: 4px;margin-bottom: 6px;margin-right: 6px;display: inline-block;height: 24px;line-height: 24px;padding: 0 12px;font-size:12px;">{{item}}</span>
          </div>
          <div style="margin-top:12px;" v-for="(item,index) in chatDelData.recordList" :key="index">
            <div :class="item.word==''?'need-hide':''" v-if="item.role==1" style="max-width: 70%;display: flex;margin-top:12px">
              <img style="width:36px;height:36px;border-radius: 18px;" :src="chatDelData.avatar" alt="">
              <div style="flex: 1;margin-left: 8px;">
                <span style="background:rgba(244, 244, 245, 1);border:1px solid rgba(229, 231, 235, 1);padding:8px;border-radius: 8px;display: inline-block;color:rgba(18, 18, 18, 1);font-size:14px;">{{item.word}}</span>
              </div>
            </div>
            <div style="clear: both"></div>
            <div :class="item.word==''?'need-hide':''" v-if="item.role==2" style="max-width: 70%;float: right;padding: 8px;background:rgba(120, 86, 255, 0.12);border: 1px solid rgba(229, 231, 235, 1);border-radius: 8px;color:rgba(18, 18, 18, 1);font-size: 14px;margin-top:12px;margin-right: 16px;">
              {{item.word}}
            </div>
            <div style="clear: both"></div>
          </div>
          <div style="height:96px"></div>
        </div>
      </div>
      <div style="width: 100%;position: fixed;bottom: 24px;left:0;padding: 0 16px;">
        <div style="height: 60px;width: 100%;display: flex;">
          <div @click="goIndex" style="width:60px;height:60px;border-radius:30px;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);background:#fff;margin-right:12px;text-align:center;line-height:60px;font-size:20px;">
            <a-icon type="home" />
          </div>
          <div style="flex: 1;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);height: 60px;border-radius: 30px;display: flex;padding: 12px 15px;background:#fff">
            <div style="color:rgba(44, 54, 74, 1);font-size:13px;font-weight: bold;margin-right:12px;width:86px;">让数字生命帮您打工赚钱</div>
            <div @click="register" style="flex:1;background:rgba(120, 86, 255, 1);border-radius: 19px;height: 38px;padding:9px 21px;text-align: center">
              <span style="color:#fff;font-size:13px;font-weight: bold;margin-left:6px;">注册数字员工</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="screenVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
          <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
            <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;position: relative;">
              <span>登录注册</span>
              <a-icon @click="closeScreen" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
            </div>
            <div style="text-align: center;margin:24px 0 13px">
              <img style="width: 71px;" src="../assets/image/head1.png" alt="">
            </div>
            <div style="text-align: center">
              <img src="../assets/image/ai.png" style="width: 131px;" alt="">
            </div>
            <div style="height: 88px;background: #fff;border-radius: 8px;margin-top: 28px;position: relative;padding: 22px 20px;text-align: center;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);">
                <a-input type="number" style="border: none;background: none;font-size:18px" placeholder="请输入手机号" />
                <span @click="getCode" style="display: inline-block;width: 263px;height: 48px;line-height: 48px;border-radius: 12px;text-align: center;color:#fff;font-size:16px;background: rgba(120, 86, 255, 1);">获取验证码</span>
            </div>
            <div style="color:rgba(103, 110, 125, 1);font-size: 12px;margin-top:30px;text-align: center;">若手机号未注册，登录后会自动注册</div>
          </div>
        </div>
      </div>
      <div v-if="pcVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
        <div style="width:100%;height: 100%;position: relative;">
          <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
          <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
            <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 24px;position: relative;">
              <span>登录注册</span>
              <a-icon @click="closePc" style="color:rgba(103, 110, 125, 1);font-size: 18px;position: absolute;right:0px;top:0px;" type="close" />
            </div>
            <div style="color:rgba(103, 110, 125, 1);font-size:13px;">请输入验证码</div>
            <div style="margin-top: 12px;">
              <a-row style="text-align: center;">
                <a-col :span="6">
                  <a-input type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="6">
                  <a-input type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="6">
                  <a-input type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
                <a-col :span="6">
                  <a-input type="number" oninput="if(value.length>1)value=value.slice(0,1)" :maxLength=1 style="display: inline-block;width: 72px;height: 72px;border-radius: 12px;background: rgba(179, 184, 193, 0.16);border: none;text-align: center;font-size:24px;"/>
                </a-col>
              </a-row>
            </div>
            <div style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;margin-top:48px">确认</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import WaveSurfer from "wavesurfer.js";
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    data() {
        return {
          urlDuration: 0,
          currentTime: 0,
          wavesurfer: null,
          voiceUrl: '/1.mp3',
          playing: false,
          chatList: [
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
            {
              role:2
            },
            {
              role:1
            },
          ],
          screenVisible: false,
          pcVisible: false,
          token: '',
          chatDelData: '',
          ifShowTitle: false,
        }
    },
    methods: {
      goShare() {
        this.$router.push("/sharePage")
      },
      playMusic() {
          this.wavesurfer.playPause.bind(this.wavesurfer)();
          this.playing = !this.playing;
      },
      beforeDestroy() {
        // 销毁 WaveSurfer 实例
        if (this.wavesurfer) {
          this.wavesurfer.destroy();
        }
      },
      goShareDel() {
        this.$router.push("/callShare")
      },
      register() {
        this.$router.push("/index")
      },
      goIndex() {
        this.$router.push("/index")
      },
      closeScreen() {
        this.screenVisible = false
      },
      sureScreen() {
        this.screenVisible = false
      },
      getCode() {
        this.screenVisible = false
        this.pcVisible = true
      },
      closePc() {
        this.pcVisible = false
      },
      goback() {
        this.$router.go(-1)
      },
      queryChatDel() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/shareCallRecordDetail', 
            params: {
              shareCallRecordToken: this.token,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data)
            this.chatDelData = response.data.data
            this.voiceUrl = response.data.data.voiceUrl
            this.drewLine()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      drewLine() {
        this.$nextTick(() => {
          this.wavesurfer = WaveSurfer.create({
              // 波形图的容器
              container: this.$refs.waveform_Ref,
              // 已播放波形的颜色
              progressColor: "#D2D2D2",
              // 未播放波形的颜色
              waveColor: "#86FCC7",
              // 波形图的高度，单位为px
              height: 68,
              
          });
          console.log(123)
          console.log(this.wavesurfer)
          // this.wavesurfer.load(require('./1.mp3'));
          this.wavesurfer.load(this.voiceUrl);
          let that = this
          this.wavesurfer.on('ready', function () {
              // 当音频加载完成后，获取时长
              that.urlDuration = that.wavesurfer.getDuration().toFixed(2);
          });
          this.wavesurfer.on('audioprocess', function (time) {
              // 'time' 参数是当前播放位置的秒数
              that.currentTime = time.toFixed(2)
              if(that.urlDuration == that.currentTime){
                  that.playing = false
              }
          });
        });
      },
      hideModel() {
        this.screenVisible = false
        this.pcVisible = false
      },
      // handleScroll(event) {
      //   console.log(event)
      //   const { scrollTop } = event.target;
      //   if(scrollTop > 125){
      //     this.ifShowTitle = true
      //   }else{
      //     this.ifShowTitle = false
      //   }
      // },
    },
    mounted() {
      this.token = this.$route.query.token
      this.queryChatDel()
      
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .index{
      width: 100%;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
    }
    .banner{
      background: url('../assets/image/top-bg.jpg') no-repeat center center / cover;
      width: 100%;
      height: 212px;
      z-index: 10000;
      /* background-attachment: fixed; */
    }
    .head-banner1{
      height: 60px;
      position: fixed;
      width: 100%;
      left: 0;
      top:0;
      overflow: hidden;
      z-index: 1000;
    }
    .head-banner2{
      background: url('../assets/image/top-bg-1.png') no-repeat center center / cover;
      height: 60px;
      position: fixed;
      width: 100%;
      left: 0;
      top:0;
      overflow: hidden;
      z-index: 1000;
    }
    .common-cdr{
      display: inline-block;
      padding: 0 8px;
      color: rgba(103, 110, 125, 1);
      line-height: 18px;
      margin-top:5px
    }
    .active-cdr{
      display: inline-block;
      padding: 0 8px;
      color: rgba(44, 54, 74, 1);
      font-weight: bold;
      background: #fff;
      height: 18px;
      line-height: 18px;
      border-radius: 6px;
    }
    .need-hide{
      display: none !important;
    }
  </style>
  