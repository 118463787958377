<template>
  <div style="width: 100%;height: 100%;background:rgba(249, 249, 249, 1);">
    <div style="height: 84px;position: fixed;background: #fff;left:0;top:0;width: 100%;padding: 40px 16px 0;">
      <a-icon @click="goback" style="font-size:20px;color:rgba(4, 4, 4, 1);float: left;" type="left" />
      <span style="color:rgba(44, 54, 74, 1);font-size:15px;margin-left:6px;">AI通话详情</span>
      <img @click="goShareDel" style="width: 20px;float: right;" src="../assets/image/share-b.png" alt="">
    </div>
    <div style="position: fixed;width:100%;height: 76px;padding:16px;background:rgba(249, 249, 249, 1);left:0;top:84px">
      <div style="width:100%;height: 60px;padding:10px 12px;background: #fff;border-radius:8px;display:flex;">
        <div style="flex: 1;">
          <div style="color:rgba(44, 54, 74, 1);font-size:14px;">{{chatDelData.name}}</div>
          <div style="color:rgba(179, 184, 193, 1);font-size:12px;">{{chatDelData.phone}}</div>
        </div>
        <span @click="aiSearch" style="display: inline-block;width: 44px;height:44px;border-radius: 22px;background:rgba(243, 245, 248, 1);text-align: center;line-height: 50px;margin-right:12px;">
          <a-icon style="color:rgba(201, 204, 211, 1);font-size:20px;" type="search" />
        </span>
        <span @click="callNumber" style="display: inline-block;width: 44px;height:44px;border-radius: 22px;background:rgba(243, 245, 248, 1);text-align: center;line-height: 54px;">
          <a-icon style="color:rgba(201, 204, 211, 1);font-size:24px;transform: rotate(90deg);" type="phone" />
        </span>
        <span v-if="chatDelData.isContacts==0" @click="addNewContact" style="display: inline-block;width: 44px;height:44px;border-radius: 22px;background:rgba(243, 245, 248, 1);text-align: center;line-height: 48px;margin-left: 12px">
          <a-icon style="color:rgba(201, 204, 211, 1);font-size:20px;" type="plus" />
        </span>
        <img @click="goDel" v-if="chatDelData.isContacts==1" style="width:44px;height:44px;border-radius: 22px;margin-left: 12px;opacity: 0.5;" src="../assets/image/user2.png" alt="">
      </div>
    </div>
    <div style="height: 100%;padding:160px 0;">
      <div style="height: 100%;padding: 12px 0 0 16px;">
        <div style="color:rgba(179, 184, 193, 1);font-size:12px;text-align: center;">{{chatDelData.callTime}}</div>
        <div style="text-align:center;color:rgba(179, 184, 193, 1);font-size:12px;margin-top: 4px;">{{chatDelData.line}}</div>
        <div :class="chatDelData.labelList.length==0?'need-hide':''" style="height: 54px;padding-right: 16px;overflow-y: auto;margin-top:8px;margin-bottom: 4px;">
          <span v-for="(item,index) in chatDelData.labelList" :key="index" style="color:rgba(103, 110, 125, 1);background:rgba(243, 245, 248, 1);border:1px solid rgba(217, 217, 217, 1);border-radius: 4px;margin-bottom: 6px;margin-right: 6px;display: inline-block;height: 24px;line-height: 24px;padding: 0 12px;font-size:12px;">{{item}}</span>
        </div>
        <div style="margin-top:12px;" v-for="(item,index) in chatDelData.recordList" :key="index">
          <div v-if="item.role==1" :class="item.word==''?'need-hide':''" style="max-width: 70%;display: flex;margin-top:12px">
            <img style="width:36px;height:36px;border-radius: 18px;" :src="chatDelData.avatar" alt="">
            <div style="flex: 1;margin-left: 8px;">
              <span style="background:rgba(244, 244, 245, 1);border:1px solid rgba(229, 231, 235, 1);padding:8px;border-radius: 8px;display: inline-block;color:rgba(18, 18, 18, 1);font-size:14px;">{{item.word}}</span>
            </div>
          </div>
          <div style="clear: both"></div>
          <div v-if="item.role==2" :class="item.word==''?'need-hide':''" style="max-width: 70%;float: right;padding: 8px;background:rgba(120, 86, 255, 0.12);border: 1px solid rgba(229, 231, 235, 1);border-radius: 8px;color:rgba(18, 18, 18, 1);font-size: 14px;margin-top:12px;margin-right: 16px;">
            {{item.word}}
          </div>
          <div style="clear: both"></div>
        </div>
        <div style="margin-top:24px;">
          <a-row>
            <a-col :span="6">
              
            </a-col>
            <a-col :span="6">
              <div v-if="chatDelData.errorCorrectionStatus==1" @click="correctMis" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane1.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">纠错学习</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==2" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane2.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">处理中</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==3" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane3.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">优化完成</div>
              </div>
              <div v-if="chatDelData.errorCorrectionStatus==4" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div style="margin:4px 0 3px;">
                  <img src="../assets/image/plane4.svg" style="width:20px;transform: rotate(-45deg);margin-top:2px" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">优化失败</div>
              </div>
            </a-col>
            <a-col :span="6">
              <div @click="collectChat(2)" v-if="chatDelData.collectStatus==1" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div>
                  <img style="width: 24px;font-weight: bold;margin-top: 8px;" src="../assets/image/star-kong.png" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">收藏</div>
              </div>
              <div @click="collectChat(1)" v-if="chatDelData.collectStatus==2" style="width:52px;height:52px;border-radius: 26px;background:#fff;box-shadow: 0px 5px 28px 0px rgba(44, 54, 74, 0.16);display:inline-block;text-align: center;z-index:10000000">
                <div>
                  <img style="width: 24px;font-weight: bold;margin-top: 8px;" src="../assets/image/star1.png" alt="">
                </div>
                <div style="color:rgba(44, 54, 74, 1);font-size:11px">取消</div>
              </div>
            </a-col>
            <a-col :span="6">
              
            </a-col>
          </a-row>
        </div>
        <div style="height:96px"></div>
      </div>
      
    </div>
    <div style="position: fixed;bottom:24px;padding: 0 16px;left:0;width:100%;">
      <div style="width: 100%;border-radius: 8px;height: 60px;padding:12px;">
        <!-- <div ref="waveform_Ref" style="display: none;"></div>
        <div @click="playMusic" v-if="!playing" style="display: inline-block;width:36px;height:36px;text-align: center;line-height: 36px;border-radius: 18px;background:#fff;;">
          <a-icon style="color:rgba(44, 54, 74, 1);font-size:24px;margin-top:6px;" type="caret-right" />
        </div>
        <div v-if="playing" @click="playMusic" style="display: inline-block;width:36px;height:36px;text-align: center;line-height: 36px;border-radius: 18px;background:#fff;;">
          <a-icon style="color:rgba(44, 54, 74, 1);font-size:24px;margin-top:6px;" type="pause" />
        </div> -->
        <!-- <div style="flex: 1;margin-left:8px;">
          <div style="color:rgba(255, 255, 255, 1);font-weight: bold;font-size:16px;">{{chatDelData.phone}}</div>
          <div style="color:rgba(179, 184, 193, 1);font-size:9px;margin-top:1px;">
            <span>{{currentTime}}</span>
            <span>{{ urlDuration }}</span>
          </div>
        </div> -->
        <audio controls style="width:100%">
          <source :src="voiceUrl" type="audio/ogg">
          您的浏览器不支持 audio 元素。
        </audio>
      </div>
    </div>
    <div v-if="shareVisible" style="position: fixed;width:100%;height:100%;z-index: 100;top:0;z-index: 100000;">
      <div style="width:100%;height: 100%;position: relative;">
        <div @click="hideModel" style="position: absolute;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);"></div>
        <div style="background: #fff;border-radius: 24px 24px 0 0;position: absolute;bottom:0;left:0;width: 100%;padding: 17px 20px;">
          <div style="font-weight: bold;font-size:16px;color:rgba(44, 54, 74, 1);text-align: center;margin-bottom: 20px;">
            <span>转发链接</span>
            <a-icon @click="closeShare" style="color:rgba(103, 110, 125, 1);float: right;font-size: 18px;" type="close" />
          </div>
          <a-input v-model="copyHtml2" id="copyHtml2" readonly="readonly" style="width:100%;height:40px;border-radius:8px;margin:0 0 20px 0;background:rgba(179, 184, 193, 0.16);"/>
          <div @click="copySite" style="height: 40px;border-radius: 8px;background: rgba(120, 86, 255, 1);text-align: center;line-height: 40px;font-size: 15px;color:#fff;">复制url地址</div>
        </div>
      </div>
    </div>
    <div v-if="ifshowStatus" style="height: 40px;line-height: 40px;text-align: center;padding: 0 16px;position: fixed;width:100%;left:0;bottom:95px;">
      <div style="border-radius: 8px;background:rgba(44, 54, 74, 1);box-shadow: 2px 2px 19px 0px rgba(120, 86, 255, 0.24);height: 40px;color:#fff;font-size:13px">{{ statusText }}</div>
    </div>
    <van-action-sheet v-model="ifShowSearch" title="AI搜索">
      <div style="text-align: center;margin:80px 0 35px">
        <a-icon style="font-size:25px;" type="search" />
      </div>
      <div style="color:rgba(164, 164, 164, 1);font-size:16px;text-align: center;margin-bottom: 82px;">正在搜索，请耐心等待...</div>
    </van-action-sheet>
    <van-action-sheet v-model="ifShowResult" title="搜索结果">
      <div style="max-height:300px;overflow-y: auto;">
        <div v-for="(item,index) in searchResult" :key="index">
          <div style="margin-top:20px;padding:0 20px;">
            <div style="height:36px;line-height:36px;text-align:center;background:rgba(243, 245, 248, 1);border-radius:8px;color:rgba(44, 54, 74, 1);font-size:12px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">企业信息</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.companyName}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">法人代表</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.juridicalPerson}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">注册资本</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.registeredCapital}}</div>
          </div>
          <div style="padding: 0 20px;margin-top:20px;">
            <div style="color:rgba(103, 110, 125, 1);font-size:12px;">成立日期</div>
            <div style="color:rgba(44, 54, 74, 1);font-size:15px;font-weight: bold;margin-top:4px;">{{item.establishTime}}</div>
          </div>
        </div>
      </div>
      <div style="padding:0 20px 24px;margin-top:24px;">
        <div @click="getResult" style="height: 40px;background:rgba(120, 86, 255, 1);border-radius: 8px;line-height: 40px;font-size:15px;text-align: center;color:#fff;">知道了</div>
      </div>
    </van-action-sheet>
  </div>
</template>
  
  <script>
  import WaveSurfer from "wavesurfer.js";
  export default {
    name: 'HelloWorld',
    data() {
        return {
          chatList: [],
          urlDuration: 0,
          currentTime: 0,
          wavesurfer: null,
          playing: false,
          recordId: '',
          chatDelData: '',
          shareVisible: false,
          copyHtml2: '',
          statusText: '',
          ifshowStatus: false,
          voiceUrl: '',
          isPlaying: false,
          duration: 0,
          ifShowSearch: false,
          searchResult: [],
          ifShowResult: false,
          ifShowTip3: false,
        }
    },
    methods: {
      playMusic() {
          this.wavesurfer.playPause.bind(this.wavesurfer)();
          this.playing = !this.playing;
      },
      beforeDestroy() {
        // 销毁 WaveSurfer 实例
        if (this.wavesurfer) {
          this.wavesurfer.destroy();
        }
      },
      goShareDel() {
        // this.$router.push("/callShare")
        this.shareVisible = true
        setTimeout(()=>{
          this.queryRobotToken()
        },500)
      },
      queryRobotToken() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/shareCallRecordToken', 
            params: {
              recordId: this.recordId
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            let token = response.data.data.shareCallRecordToken
            let url = ''
            // let protocol = window.location.protocol;
            // let host = window.location.host;
            url = 'https://www.anybridges.com/callShare?token='+token
            console.log(url)
            let text = url
            let input = document.getElementById("copyHtml2");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.copyHtml2 = url
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      closeShare() {
        this.shareVisible = false
      },
      copySite() {
        this.shareVisible = false
        this.statusText = '链接已复制，快去分享吧'
        this.ifshowStatus = true
        setTimeout(()=>{
          this.ifshowStatus = false
        },1500)
      },
      goback() {
        this.$router.go(-1)
      },
      queryChatDel() {
        this.axios( 
          {
            method:'get',
            url:'/api/callData/callRecordDetail', 
            params: {
              recordId: this.recordId,
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.chatDelData = response.data.data
            this.voiceUrl = response.data.data.voiceUrl
            this.drewLine()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      collectChat(collectStatus) {
        this.axios.post('/api/callData/updateCollectStatus', {
          recordId: this.recordId,
          collectStatus: collectStatus
        })
        .then(response=> {
          if(response.data.code == 0){
            this.queryChatDel()
            if(collectStatus == 1){
              this.$toast('取消成功')
            }else{
              this.$toast('收藏成功')
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      drewLine() {
        this.$nextTick(() => {
          this.wavesurfer = WaveSurfer.create({
            // 波形图的容器
            container: this.$refs.waveform_Ref,
            // 已播放波形的颜色
            progressColor: "#D2D2D2",
            // 未播放波形的颜色
            waveColor: "#86FCC7",
            // 波形图的高度，单位为px
            height: 68,
          });
          this.wavesurfer.load(this.voiceUrl);
          let that = this
          this.wavesurfer.on('ready', function () {
            // 当音频加载完成后，获取时长
            that.urlDuration = that.wavesurfer.getDuration().toFixed(2);
          });
          this.wavesurfer.on('audioprocess', function (time) {
            // 'time' 参数是当前播放位置的秒数
            that.currentTime = time.toFixed(2)
            if(that.urlDuration == that.currentTime){
                that.playing = false
            }
          });
        });
      },
      callNumber() {
        window.location.href = `tel:${this.chatDelData.phone}`;
      },
      addNewContact() {
        this.$router.push({
          path: '/editContact',
          query:{
            id: '',
            edit: true,
            name: '',
            phone: this.chatDelData.phone,
            remark: '',
            recordId: this.recordId,
          }
        });
      },
      hideModel() {
        this.shareVisible = false
      },
      togglePlay() {
        const audio = this.$refs.audio;
        if (this.isPlaying) {
          audio.pause();
        } else {
          audio.play();
        }
        this.isPlaying = !this.isPlaying;
      },
      updateTime() {
        this.currentTime = this.$refs.audio.currentTime;
      },
      setDuration() {
        this.duration = this.$refs.audio.duration;
      },
      formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' + secs : secs}`;
      },
      seek() {
        this.$refs.audio.currentTime = this.currentTime;
      },
      goDel() {
        this.$router.push({
            path: '/contactDel',
            query:{
                id: this.chatDelData.contactsId,
            }
        });
      },
      correctMis() {
        this.axios.post('/api/callData/errorCorrection', {
          recordId: this.recordId,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.statusText = '错误话单已发送，请等待处理结果'
            this.ifshowStatus = true
            setTimeout(()=>{
              this.ifshowStatus = false
            },1500)
            this.queryChatDel()
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      aiSearch() {
        this.ifShowSearch = true
        this.axios( 
          {
            method:'get',
            url:'/api/contacts/aiSearch', 
            params:{
              phone: this.chatDelData.phone
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            this.ifShowSearch = false
            this.searchResult = response.data.data
            if(this.searchResult.length > 0){
              this.ifShowResult = true
            }else{
              this.ifShowTip3 = true
              setTimeout(()=>{
                this.ifShowTip3 = false
              },1500)
            }
          }else{
            this.$toast(response.data.userTip);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      getResult() {
        this.ifShowResult = false
      },
    },
    mounted() {
      this.recordId = this.$route.query.id
      this.queryChatDel()
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .need-hide{
      display: none !important;
    }
  </style>
  